import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import requestService from 'services/http/interceptor/request-service';
import { showError } from 'utils/showError';
import { endpoint } from 'services/endpoint';
import { IProfileError, ITransactionsRequest, ITransactionsResponse } from './transactions-types';
import { clearErrors } from './transactions-slice';

export const getTransactions = createAsyncThunk(
  'settingsTransactions/getTransactions',
  async (payload: ITransactionsRequest, thunkApi) => {
    try {
      const response = await requestService.get(endpoint.settings.GET_TRANSACTIONS, { ...payload });
      thunkApi.dispatch(clearErrors());
      return response.data;
    } catch (error) {
      const err = error as IProfileError;

      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
      }
    }
  }
);
