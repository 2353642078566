import { Fragment, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { router } from './routes';
import './languages/i18n';
import { Loader } from 'ui/Loader';
import { ERoutes } from 'enum/types';
import PublicLayout from 'layouts/PublicLayout';
import { PublicRoutes } from 'routes/PublicRoutes';
import SettingsLayout from 'layouts/SettingsLayout/SettingsLayout';
import { SettingsRoutes } from 'routes/SettingsRoutes';

export function App() {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Routes>
          <Route path={ERoutes.home} element={<PublicLayout />}>
            {PublicRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<Suspense fallback={<Loader />}>{route.element}</Suspense>}
              />
            ))}
          </Route>
          <Route path={ERoutes.settings} element={<SettingsLayout />}>
            {SettingsRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<Suspense fallback={<Loader />}>{route.element}</Suspense>}
              >
                {route?.children &&
                  route.children.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<Suspense fallback={<Loader />}>{route.element}</Suspense>}
                    />
                  ))}
              </Route>
            ))}
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}
