import { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  image: string;
  text?: string;
  title?: string;
  link?: string;
  textBtn: string;
  onButton?: () => void;
}

export const PopupAuth: FC<IProps> = ({ image, text, title, link, textBtn, onButton }) => (
  <div className='form form--authorization'>
    <div className='form-icon'>
      <img src={image} alt='success' />
    </div>
    {title ? <h2 className='form-title'>{title}</h2> : null}
    <div className='form__body'>
      <div className='form-text form-text--ta-center'>
        <p>{text}</p>
      </div>
    </div>
    <div className='form__extra'>
      {link ? (
        <Link to={link} className='button button--full-width'>
          {textBtn}
        </Link>
      ) : (
        <button onClick={onButton} type='button' className='link'>
          {textBtn}
        </button>
      )}
    </div>
  </div>
);
