import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from 'enum/types';
import { IMyComentsResponse, IMyCommentsState } from './myComments-types';
import { getMyComments } from './myComments-thunks';

const initialState: IMyCommentsState = {
  myComments: null,
  loadingState: LoadingStatus.NEVER,
  success: '',
  error: '',
  errors: null,
  popup: '',
};

export const myCommentsSlice = createSlice({
  name: 'myComments',
  initialState,
  reducers: {
    resetTransactionsState: () => initialState,
    clearErrors: (state) => {
      state.error = '';
      state.errors = null;
    },
    clearSuccess: (state) => {
      state.success = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyComments.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(getMyComments.fulfilled, (state, { payload }: PayloadAction<IMyComentsResponse>) => {
        state.loadingState = LoadingStatus.SUCCESS;
        state.myComments = payload;
      });
  },
});

export const { resetTransactionsState, clearErrors, clearSuccess } = myCommentsSlice.actions;
export default myCommentsSlice.reducer;
