import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { FC, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getProfileSelector } from 'store/redux-toolkit/settings/account/account-selector';
import { getProfile } from 'store/redux-toolkit/settings/account/account-thunks';
import { HeaderBar } from './HeaderBar/HeaderBar';
import { listLink } from './listLink';
import { useTranslation } from 'react-i18next';

export const SettingsBar: FC = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(getProfileSelector);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  return (
    <div className='profile-bar'>
      <HeaderBar name={profile?.username} urlImg={profile?.avatar_url} />
      <ul className='profile-nav'>
        {listLink.map((link) => (
          <li key={link.title} className='profile-nav__item'>
            <Link
              to={link.link}
              className={classNames('d-flex profile-nav__link', {
                active: pathname.includes(link.link),
              })}
            >
              <span className='d-flex profile-nav__icon'>
                <link.svg />
              </span>
              {t(link.title)}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
