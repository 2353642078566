import React, { FC } from 'react';
import { onScrollUp } from 'layouts-element/Footer/scrollUp';

export const ButtonUp: FC<{ scrollPosition: number }> = ({ scrollPosition }) => {
  return (
    <button
      type='button'
      aria-label='ButtonUp'
      onClick={onScrollUp}
      className='to-top-btn to-top-btn-custom'
    >
      <div className='arrow-down'>
        <span className='arrow-down__elem'></span>
        <span className='arrow-down__elem'></span>
        <span className='arrow-down__elem'></span>
      </div>
    </button>
  );
};
