export const BASE_URL = 'dev-playch.dev-now.xyz/gateway';
export const API_BASE_URL =
  window.location.hostname === 'localhost'
    ? `http://${BASE_URL}/api/v1`
    : `${window.location.origin}/api/v1`;

// export const WEBSOCKET_URL =
//   window.location.hostname === 'localhost'
//     ? `wss://${BASE_URL}:8443`
//     : `wss://${window.location.hostname}:8443`;

export const endpoint = {
  auth: {
    LOGIN: '/login',
    REFRESH: '/token/refresh',
    REGISTRATION: '/registration',
    LOGOUT: '/logout',
    PASSWORD_RESET: '/reset/password',
    CHANGE_PASSWORD: '/profile/security/password',
    PASSWORD_RESET_SEND: '/reset/password/send',
    TOKEN_REFRESH: '/token/refresh',
    VERIFY_EMAIL: (token: string) => `/verify/email/${token}`,
  },
  settings: {
    GET_PROFILE: '/profile/',
    POST_PROFILE: '/profile/',
    GET_PROFILE_ADDRESS: '/profile/address',
    POST_PROFILE_ADDRESS: '/profile/address',
    POST_USERNAME: '/profile/username',
    POST_EMAIL: '/profile/email',
    POST_EMAIL_VERIFY: (params: string) => `/profile/email/${params}`,
    GET_NOTIFICATION: '/profile/settings',
    GET_TRANSACTIONS: '/profile/payments',
    GET_MY_COMMENTS: '/profile/comments',
  },
  support: {
    CONTACT_US: '/contact_us/send',
  },
  updates: {
    POST_LIKE: '/blog/post/like',
    GET_POSTS: '/blog/posts',
  },
  comments: {
    POST_COMMENTS: '/comments/',
    GET_COMMENTS: '/comments/',
    POST_SUB_COMMENTS: '/comments/sub_comments',
    GET_SUB_COMMENTS: (param: string) => `/comments/${param}/sub_comments`,
  },
  metrics: {
    METRICS: '/metrics/development',
  },
  peyments: {
    GET_PAYMENTS: '/payments/bank',
    POST_PAYMENTS: '/payments/paypal/link',
    PAYPAL_CANCEL: '/payments/paypal/webhook/cancel',
    PAYPAL_SUCCESS: '/payments/paypal/webhook/success',
  },
};
