import { FC } from 'react';
import { resetSchema } from '../../../form-validations/login-validation';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import Input from 'ui/Formik/Input';
import { Link } from 'react-router-dom';
import { ERoutes, LoadingStatus } from 'enum/types';
import logo from '../../../assets/images/Logo-dark.svg';
import { IForgotForm, IPropsForgotForm } from './types';
import { useAppSelector } from 'hooks/redux';
import { LoadingSmall } from 'ui/LoadingSmall/LoadingSmall';

const initialValues = {
  email: '',
};

export const ForgotForm: FC<IPropsForgotForm> = ({ forgotPassSubmit }) => {
  const { t } = useTranslation();
  const { loadingState } = useAppSelector((store) => store.auth);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={resetSchema}
      onSubmit={(values: IForgotForm, { setSubmitting }) => {
        forgotPassSubmit(values);
        setSubmitting(false);
      }}
      validateOnBlur
      enableReinitialize
    >
      {({ isSubmitting, isValid, dirty }) => {
        return (
          <Form className='form form--authorization'>
            <div className='form__logo'>
              <img src={logo} alt='logo' />
            </div>
            <h2 className='form-title'>{t('Forms.Forgot.title')}</h2>
            <div className='form__body'>
              <div className='form-text'>
                <p>{t('Forms.Forgot.description')}</p>
              </div>
              <div className='input'>
                <div className='input-wrapper'>
                  <Field
                    component={Input}
                    name='email'
                    className='input-item'
                    type='email'
                    placeholder={t('Forms.Forgot.email')}
                  />
                </div>
              </div>
            </div>
            <div className='form-submit'>
              <button
                disabled={!(isValid && dirty) || isSubmitting}
                className='button button--full-width button--loading'
                type='submit'
              >
                {loadingState === LoadingStatus.LOADING ? <LoadingSmall /> : t('Global.send')}
              </button>
            </div>

            <div className='form__extra'>
              <p>
                {t('Forms.Forgot.sign_in')}
                <Link to={ERoutes.login} className='link'>
                  Sign in
                </Link>
              </p>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
