import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const TableHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='table-header'>
      <div className='tr'>
        <div className='td'>
          <div className='td-name'>
            <p>{t('Page.Settings.Transaction.transaction_t_date')}</p>
          </div>
        </div>
        <div className='td'>
          <div className='td-name'>
            <p>{t('Page.Settings.Transaction.transaction_t_description')}</p>
          </div>
        </div>
        <div className='td'>
          <div className='td-name'>
            <p>{t('Page.Settings.Transaction.transaction_t_price')}</p>
          </div>
        </div>
        <div className='td'>
          <div className='td-name'>
            <p>{t('Page.Settings.Transaction.transaction_t_status')}</p>
          </div>
        </div>
        <div className='td'></div>
      </div>
    </div>
  );
};
