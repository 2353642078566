import { FC, MouseEvent, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import avatar from '../../assets/images/default-avatar.png';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { logout } from 'store/redux-toolkit/auth/auth-thunks';
import { BurgerCloseSvg, BurgerSvg, LogoSvg } from 'assets/svg-icon';
import { getDataUserSelector, getTokenSelector } from 'store/redux-toolkit/auth/app-selector';
import { MenuPopup } from './MenuPopup/MenuPopup';
import classNames from 'classnames';
import useOutsideClick from 'hooks/useOutsideClick';
import { Language } from 'ui/Language/Language';
import { setFirstBurger } from 'store/redux-toolkit/app/app-slice';
import { getFirstBurgerSelector } from 'store/redux-toolkit/app/app-selector';

export const AfterRegistrationHeader: FC = () => {
  const dispatch = useAppDispatch();
  const burger = useAppSelector(getFirstBurgerSelector);
  const token = useAppSelector(getTokenSelector);
  const userInfo = useAppSelector(getDataUserSelector);
  const ref = useRef<HTMLDivElement | null>(null);
  const [onOpen, setOnOpen] = useState<boolean>(false);
  useOutsideClick(ref, () => setOnOpen(false));

  const onLogout = (event: MouseEvent) => {
    event.preventDefault();
    dispatch(logout({ token }));
  };

  const onPopupLink = (event: React.MouseEvent) => {
    const { id } = event.currentTarget as HTMLLinkElement;

    setOnOpen(!onOpen);
    id && dispatch(setFirstBurger(!burger));
  };

  const onBurger = () => {
    dispatch(setFirstBurger(!burger));

    if (!burger) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  };

  return (
    <header id='header-id' className='header header--account'>
      <div className='header__container header__container--full header__container-custom'>
        <div className='header-left'>
          <Link to='/' className='logo'>
            <LogoSvg />
          </Link>
        </div>
        <div className={classNames('nav-block nav-block-custom', { active: burger })}>
          <div className='nav-block__inner'>
            {/* <nav className='main-nav main-nav--account-type'>
              <div className='nav-item'>
                <Link to='/' className='nav-item__link'>
                  About PLAYCH
                </Link>
              </div>
            </nav> */}
            <div className='header-right'>
              <Language />
              {/* <div className='d-flex header-right__group'>
                <div className='header-theme'>
                  <button className='d-flex header-theme__btn header-theme__btn--light '>
                    <SunSvg />
                  </button>
                  <button className='d-flex  header-theme__btn header-theme__btn--dark active'>
                    <MoonSvg />
                  </button>
                </div>
              </div> */}
              <div
                ref={ref}
                className={classNames('header-user header-user-custom', { active: onOpen })}
              >
                <div onClick={onPopupLink} className='d-flex header-user__item'>
                  <div className='my-avatar my-avatar--size-32'>
                    <div className='d-flex my-avatar__inner'>
                      <img src={userInfo?.avatar_url || avatar} alt='avatar' />
                    </div>
                  </div>
                  <span className='header-user__name'>{userInfo?.username}</span>
                </div>
                <MenuPopup onLogout={onLogout} onPopupLink={onPopupLink} />
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={onBurger}
          type='button'
          className={classNames('nav-btn', { active: burger })}
        >
          <BurgerSvg />
          <BurgerCloseSvg />
        </button>
      </div>
    </header>
  );
};
