import { FC } from 'react';
import { FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { CheckSvg } from 'assets/svg-icon';
import { ERoutes } from 'enum/types';

interface CustomInputProps {
  text?: string;
}

const CheckBox: FC<CustomInputProps & FieldProps> = ({
  field,
  form: { touched, errors },
  text,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`checkbox ${
        field.name === 'terms_of_service' || field.name === 'receive_news' ? '' : 'checkbox--mb-0'
      }`}
    >
      <label className='checkbox__label'>
        <input type='checkbox' {...field} {...props} className='hidden' />
        <span className=' checkbox__item'>
          <span className='checkbox__item-icon'>
            <CheckSvg />
          </span>
        </span>
        {field.name === 'rememberMe' && <span className='checkbox__text'>Remember me</span>}
        {field.name === 'terms_of_service' && (
          <span className='checkbox__text'>
            {t('Forms.Sign_up.btn_confirm_terms')}
            <a href={ERoutes.terms} target='_blank' rel='noopener noreferrer'>
              Terms of Service
            </a>
          </span>
        )}
        {field.name === 'receive_news' && <span className='checkbox__text'>{text}</span>}
        {field.name === 'checkbox' && <span className='checkbox__text'>{text}</span>}
      </label>

      {touched[field.name] && errors[field.name] && (
        <p className='input-notification--error'>{t(String(errors[field.name]))}</p>
      )}
    </div>
  );
};

export default CheckBox;
