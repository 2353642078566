import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from 'enum/types';
import { getComments, getSubComments, postComments, postSubComments } from './comments-thunks';
import { ICommentsResponse, ICommentsResponsePost, ICommentsState } from './comments-types';

const initialState: ICommentsState = {
  loadingState: LoadingStatus.NEVER,
  comments: null,
  subComments: null,
  success: '',
  error: '',
  errors: null,
};

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    resetCommentsState: () => initialState,
    clearErrors: (state) => {
      state.error = '';
      state.errors = null;
    },
    clearSuccess: (state) => {
      state.success = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postComments.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(
        postComments.fulfilled,
        (state, { payload }: PayloadAction<ICommentsResponsePost>) => {
          state.loadingState = LoadingStatus.SUCCESS;
          if (payload.sub?.published) {
            if (state.comments?.data?.comments) {
              state.comments?.data.comments.forEach((comment) => {
                if (comment.uuid === payload.sub.parent_comment_uuid) {
                  console.log(comment.uuid, payload.sub);

                  if (!comment.sub_comments) {
                    comment.sub_comments = [payload.data];
                    return;
                  }

                  comment.sub_comments.unshift(payload.data);
                  comment.sub_comments_count = comment.sub_comments_count + 1;
                }
              });
            } else {
              state.comments?.data.comments.forEach((comment, index) => {
                if (comment.uuid === payload.sub.parent_comment_uuid) {
                  // @ts-ignore
                  state.comments.data.comments = payload.data;
                }
              });
              if (!state.comments?.data?.comments) {
                console.log(state.comments?.data, payload);
              }
            }
          } else {
            if (state.comments?.data?.comments) {
              state.comments?.data.comments.unshift(payload.data);
            } else {
              state.comments = {
                data: {
                  comments: [payload.data],
                  total: Number(state.comments?.data.comments) + 1,
                },
              };
            }
          }
        }
      )
      .addCase(postComments.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.errors = payload;
      })
      .addCase(getComments.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(getComments.fulfilled, (state, { payload }: PayloadAction<ICommentsResponse>) => {
        state.loadingState = LoadingStatus.SUCCESS;
        state.comments = payload;
      })
      .addCase(getComments.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.errors = payload;
      })
      .addCase(postSubComments.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(postSubComments.fulfilled, (state, { payload }) => {
        state.loadingState = LoadingStatus.SUCCESS;
      })
      .addCase(postSubComments.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.errors = payload;
      })
      .addCase(getSubComments.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(getSubComments.fulfilled, (state, { payload }: PayloadAction<ICommentsResponse>) => {
        state.loadingState = LoadingStatus.SUCCESS;
        state.comments?.data.comments.forEach((comment) => {
          if (comment.uuid === payload?.sub?.commentUUID) {
            comment.sub_comments = payload.data.comments;
          }
        });
      })
      .addCase(getSubComments.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.errors = payload;
      });
  },
});

export const { resetCommentsState, clearErrors, clearSuccess } = commentsSlice.actions;
export default commentsSlice.reducer;
