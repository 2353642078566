import { FC } from 'react';
import { BackersGoldBlok } from './BackersGoldBlok/BackersGoldBlok';
import { BackersSilverBlok } from './BackersSilverBlok/BackersSilverBlok';
import { OtherBackers } from './OtherBackers/OtherBackers';
import { useTranslation } from 'react-i18next';

export const Backers: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='container container--big'>
      <h2 className='home-title'>{t('Page.Backers.title')}</h2>
      <div className='info-text info-text--ta-center info-text--width-1090 '>
        <p>{t('Page.Backers.sub_title')}</p>
      </div>
      <div className='backers-box'>
        <BackersGoldBlok />
        <BackersSilverBlok />
        <OtherBackers />
      </div>
    </div>
  );
};
