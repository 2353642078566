import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import requestService from 'services/http/interceptor/request-service';
import { showError } from 'utils/showError';
import { endpoint } from 'services/endpoint';
import { IProfileError } from '../settings/account/account-types';
import { notificationContainer } from 'ui/notificationContainer';
import { t } from 'i18next';
import { clearErrors } from './updates-slice';
import { IDataRespons, IPaymentRequest, IPaymentResponse } from './updates-types';

export const getPeymentsInfo = createAsyncThunk('updates/getPeymentsInfo', async (_, thunkApi) => {
  try {
    const response = await requestService.get(endpoint.peyments.GET_PAYMENTS);
    thunkApi.dispatch(clearErrors());
    return response.data;
  } catch (error) {
    const err = error as IProfileError;
    if (isAxiosError(error)) {
      if (error.response?.data) {
        showError(error);
      }
    }

    return thunkApi.rejectWithValue(err);
  }
});

export const getPeymentLink = createAsyncThunk(
  'updates/getPeymentLink',
  async (payload: IDataRespons, thunkApi) => {
    try {
      const response = await requestService.post(endpoint.peyments.POST_PAYMENTS, payload.api);

      thunkApi.dispatch(clearErrors());
      payload.close(false);
      console.log(response.data.data.link);

      const win = window.open(response.data.data.link, '_blank');
      //@ts-ignore
      win.focus();

      return response.data;
    } catch (error) {
      const err = error as IProfileError;
      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
      }

      return thunkApi.rejectWithValue(err);
    }
  }
);
