import { CloseToastifySvg, ErrorSvg, SuccessSvg } from 'assets/svg-icon';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IToastifyComponent } from './types';

const ToastifyComponent: FC<IToastifyComponent> = ({ message, toastProps }) => {
  const { t } = useTranslation();

  return (
    <div className={`notification notification--${toastProps?.type} active`}>
      <div className='notification__close'>
        <button type='button'>
          <CloseToastifySvg />
        </button>
      </div>
      <div className='notification__icon'>
        {toastProps?.type === 'error' && <ErrorSvg />}
        {toastProps?.type === 'success' && <SuccessSvg />}
        {toastProps?.type === 'warning' && <ErrorSvg />}
        {toastProps?.type === 'info' && <ErrorSvg />}
        {toastProps?.type === 'default' && <ErrorSvg />}
      </div>
      <div className='notification__info'>
        <div className='notification__title'>
          <p>
            {toastProps?.type === 'error' && t('Global.error')}
            {toastProps?.type === 'success' && t('Global.success')}
            {toastProps?.type === 'warning' && t('Global.warning')}
            {toastProps?.type === 'info' && t('Global.info')}
            {toastProps?.type === 'default' && t('Trade.trade_notif')}
          </p>
        </div>
        <div className='notification__text'>
          <p>{t(message)}</p>
        </div>
      </div>
    </div>
  );
};

export default ToastifyComponent;
