import { CloseSvg } from 'assets/svg-icon';
import { LoadingStatus } from 'enum/types';
import { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import patreon from '../../assets/images/donate-patreon.png';
import coffee from '../../assets/images/donate-coffee.png';
// import { LoadingSmall } from 'ui/LoadingSmall/LoadingSmall';

interface IProps {
  onClosePpopUp: () => void;
  onGetSum: (event: ChangeEvent) => void;
  handleInput: (event: ChangeEvent) => void;
  onSubmit: () => void;
  num: string;
  amount: string;
  loading: LoadingStatus;
}

export const BakePopUp: FC<IProps> = ({
  onSubmit,
  onClosePpopUp,
  onGetSum,
  handleInput,
  num,
  amount,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <div className='popup-window'>
      <div className='popup-window__inside'>
        <div className='popup popup--width-460'>
          <button onClick={onClosePpopUp} className='popup__close'>
            <CloseSvg />
          </button>
          <div className='popup-header'>
            <h2 className='popup-title'>{t('Page.Project.back_this_project')}</h2>
          </div>
          {/* <div className='popup-body'>
            <div className='range-options'>
              <div className='radio radio--range-type'>
                <label className='radio__label'>
                  <input
                    onChange={onGetSum}
                    id='5'
                    type='radio'
                    className='hidden'
                    name='one-group'
                    checked={num === '5'}
                  />
                  <span className='radio__item'></span>
                  <span className='radio__text'> 5$ </span>
                </label>
              </div>
              <div className='radio radio--range-type'>
                <label className='radio__label'>
                  <input
                    onChange={onGetSum}
                    id='15'
                    type='radio'
                    className='hidden'
                    name='one-group'
                    checked={num === '15'}
                  />
                  <span className='radio__item'></span>
                  <span className='radio__text'> 15$ </span>
                </label>
              </div>
              <div className='radio radio--range-type'>
                <label className='radio__label'>
                  <input
                    onChange={onGetSum}
                    id='25'
                    type='radio'
                    className='hidden'
                    name='one-group'
                    checked={num === '25'}
                  />
                  <span className='radio__item'></span>
                  <span className='radio__text'> 25$ </span>
                </label>
              </div>
              <div className='radio radio--range-type'>
                <label className='radio__label'>
                  <input
                    onChange={onGetSum}
                    id='custom'
                    type='radio'
                    className='hidden'
                    name='one-group'
                    checked={num === 'custom'}
                  />
                  <span className='radio__item'></span>
                  <span className='radio__text'>{t('Page.Project.back_this_project_custom')}</span>
                </label>
              </div>
            </div>
            {num === 'custom' ? (
              <div className='input'>
                <div className='input-wrapper'>
                  <input
                    onChange={handleInput}
                    value={amount}
                    className='input-item'
                    type='number'
                    placeholder={`* ${t('Page.Project.back_this_project_holder')}`}
                  />
                  <div className='input-actions'>
                    <span className='input-icon'>
                      <svg
                        width='10'
                        height='14'
                        viewBox='0 0 10 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1.67041 9.33003C1.67041 10.62 2.66041 11.66 3.89041 11.66H6.40041C7.47041 11.66 8.34041 10.75 8.34041 9.63003C8.34041 8.41003 7.81041 7.98003 7.02041 7.70003L2.99041 6.30003C2.20041 6.02003 1.67041 5.59003 1.67041 4.37003C1.67041 3.25003 2.54041 2.34003 3.61041 2.34003H6.12041C7.35041 2.34003 8.34041 3.38003 8.34041 4.67003'
                          stroke='#292D32'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M5.00049 1V13'
                          stroke='#292D32'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            ) : null}
          </div> */}
          {/* <div className='popup-footer'>
            <div className='form-submit form-submit--mob-column'>
              <button
                onClick={onClosePpopUp}
                className='button button--type3 button--full-width'
                type='button'
              >
                {t('Global.cancel')}
              </button>
              <button onClick={onSubmit} className='button button--full-width' type='button'>
                {loading === LoadingStatus.LOADING ? <LoadingSmall /> : t('Global.confirm')}
              </button>
            </div>
          </div> */}
          <div className='popup-body'>
            <a
              target='_blank'
              href='https://www.buymeacoffee.com/playch'
              className='button button--full-width button--donate button--buy-coffee '
              rel='noreferrer'
            >
              <img src={coffee} alt='buy me a coffee' />
            </a>
            <div className='cred-or cred-or--form '>
              <div className='cred-or__line'></div>
              <div className='cred-or__text'>Or</div>
              <div className='cred-or__line'></div>
            </div>
            <a
              target='_blank'
              href='https://www.patreon.com/playch'
              className='button button--full-width button--donate button--patreon'
              rel='noreferrer'
            >
              <img src={patreon} alt='patreon' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
