import {
  CloseTagSvg,
  DiscordSvg,
  EmailGreySvg,
  FacebookSvg,
  InstagramSvg,
  TwitterFooterSvg,
  TwitterSvg,
} from 'assets/svg-icon';

export const Socials: React.FC = () => (
  <div className='project-share'>
    <a
      target='_blank'
      rel='noreferrer'
      href='https://www.facebook.com/playchtv/'
      className='project-share__btn project-share__btn--facebook'
      aria-label='Facebook'
    >
      <FacebookSvg />
    </a>
    <a
      target='_blank'
      rel='noreferrer'
      href='https://twitter.com/playchtv/'
      className='project-share__btn project-share__btn--twitter'
      aria-label='Twitter'
    >
      <TwitterFooterSvg />
    </a>
    <a
      target='_blank'
      rel='noreferrer'
      href='https://www.instagram.com/playchtv/'
      className='project-share__btn project-share__btn--instagram'
      aria-label='Instagram'
    >
      <InstagramSvg />
    </a>
    <a
      target='_blank'
      rel='noreferrer'
      href='https://mas.to/@playch'
      className='project-share__btn project-share__btn--discord'
      aria-label='Discord'
    >
      <DiscordSvg />
    </a>
    {/* <button className='project-share__btn project-share__btn--mail'>
      <EmailGreySvg />
    </button> */}
    {/* <button className='project-share__btn project-share__btn--code'>
      <CloseTagSvg />
    </button> */}
  </div>
);
