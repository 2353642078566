import { FC } from 'react';
import sponsor from '../../../assets/images/sponsor-logo1.png';
import sponsor2 from '../../../assets/images/sponsor-logo2.png';
import backer from '../../../assets/images/backer-lvl1.png';

const data = [sponsor, sponsor2, sponsor, sponsor2];

export const BackersGoldBlok: FC = () => {
  return (
    <div className='backers-list backers-list--big'>
      {data.map((item, index) => (
        <div key={index} className='backer'>
          <div className='d-flex backer__logo-wrap'>
            <div className='d-flex backer__logo'>
              <img src={item} alt='sponsor' />
            </div>
          </div>
          <div className='d-flex backer__lvl'>
            <img src={backer} alt='lvl' />
          </div>
          <div className='backer__info'>
            <p>
              User name <br />
              or Company name
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
