import { FC } from 'react';
import { changePassShema } from '../../../form-validations/login-validation';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { IPropsResetPass, IResetPass } from 'pages/Auth/ResetPassword/types';
import PasswordField from 'ui/Formik/PasswordField';
import { LoadingSmall } from 'ui/LoadingSmall/LoadingSmall';
import { LoadingStatus } from 'enum/types';
import { useAppSelector } from 'hooks/redux';

const initialValues = {
  password: '',
  password_confirmation: '',
};

export interface IPropsChangePass {
  changePasswordSubmit: (value: IResetPass) => void;
}

export const ChangePasswordForm: FC<IPropsChangePass> = ({ changePasswordSubmit }) => {
  const { t } = useTranslation();
  const { loadingState } = useAppSelector((store) => store.auth);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={changePassShema}
      onSubmit={(values: IResetPass, { setSubmitting, resetForm }) => {
        changePasswordSubmit(values);
        setSubmitting(false);
        resetForm();
      }}
      validateOnBlur
      enableReinitialize
    >
      {({ isSubmitting, isValid, dirty }) => {
        return (
          <Form className='account-wrap'>
            <div className='block-title-group'>
              <h2 className='block-subtitle'>
                {t('Page.Settings.Security.change_password_title')}
              </h2>
              <div className='block-text block-text--smaller'>
                <p>{t('Page.Settings.Security.change_password_description')}</p>
              </div>
            </div>

            <div className='account-row'>
              <div className='form-group'>
                <div className='input'>
                  <p className='input-name'>{t('Page.Settings.Security.new_password')}</p>
                  <div className='input-wrapper'>
                    <Field
                      className='input-item input-item--pr-90'
                      name='password'
                      component={PasswordField}
                      placeholder={t('Forms.Sign_up.password')}
                    />
                  </div>
                </div>
                <div className='input input--mb-0'>
                  <p className='input-name'>{t('Page.Settings.Security.retype_password')}</p>
                  <div className='input-wrapper'>
                    <Field
                      className='input-item input-item--pr-90'
                      name='password_confirmation'
                      component={PasswordField}
                      placeholder={t('Forms.Sign_up.password')}
                    />
                  </div>
                </div>
              </div>
              <div className='password-description'>
                <h2 className='block-subtitle block-subtitle--smaller'>
                  {t('Page.Settings.Security.info_password_title')}
                </h2>
                <div className='block-text block-text--smaller block-text--mb-16'>
                  <p>{t('Page.Settings.Security.info_password_description')}</p>
                </div>
                <div className='input-notification input-notification--bigger input-notification--fw-500'>
                  <p>{t('Page.Settings.Security.info_password_mast_to_1')}</p>
                  <p>{t('Page.Settings.Security.info_password_mast_to_2')}</p>
                  <p>{t('Page.Settings.Security.info_password_mast_to_3')}</p>
                </div>
              </div>
            </div>
            <div className='account-row account-row--mt-24'>
              <div className='form-confirm form-confirm--575-no-wrap '>
                <button
                  disabled={!(isValid && dirty) || isSubmitting}
                  className='button button--full-width'
                  type='submit'
                >
                  {loadingState === LoadingStatus.LOADING ? (
                    <LoadingSmall />
                  ) : (
                    t('Page.Settings.btn_save')
                  )}
                </button>
                {/* <button className='button button--full-width button--type3' type='button'>
                  Discard changes
                </button> */}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
