import { FC } from 'react';
import sponsor from '../../../assets/images/sponsor-logo1.png';
import sponsor2 from '../../../assets/images/sponsor-logo2.png';
import sponsor3 from '../../../assets/images/sponsor-logo3.png';
import backer from '../../../assets/images/backer-lvl3.png';
import patreon from '../../../assets/images/patreon.png';
import { FacebookSvg, InstagramSvg, TwitterSvg } from 'assets/svg-icon';

const data = [
  {
    sponsor: sponsor,
    svg: FacebookSvg,
  },
  {
    sponsor: sponsor2,
    svg: InstagramSvg,
  },
  {
    sponsor: sponsor3,
    svg: TwitterSvg,
  },
];

export const OtherBackers: FC = () => {
  return (
    <div className='backers-list backers-list--like-list'>
      {data.map((item, index) => (
        <div key={index} className='backer backer--list-type'>
          <div className='d-flex backer__info-box'>
            <div className='d-flex backer__logo-wrap'>
              <div className='d-flex backer__logo'>
                <img src={item.sponsor} alt='sponsor' />
              </div>
            </div>
            <div className='d-flex backer__lvl'>
              <img src={backer} alt='lvl' />
            </div>
            <div className='backer__details'>
              <div className='backer__info'>
                <p>User name or Company name</p>
              </div>
              <div className='backer-extra'>
                <p className='backer-extra__date'>November 16, 2024</p>
                <span className='backer-extra__label'>on</span>
                <div className='d-flex backer-extra__logo'>
                  <img src={patreon} alt='patreon' />
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex backer__social'>
            <div className='footer-social footer-social--backers'>
              <a
                href='/'
                className='d-flex footer-social__link d-flex footer-social__link--facebook'
              >
                <item.svg />
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
