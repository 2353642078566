/* eslint-disable import/no-named-as-default */
import { combineReducers } from '@reduxjs/toolkit';
import auth from './redux-toolkit/auth/auth-slice';
import app from './redux-toolkit/app/app-slice';
import settingsAccount from './redux-toolkit/settings/account/account-slice';
import settingsNotification from './redux-toolkit/settings/notification/notification-slice';
import contactUsSlice from './redux-toolkit/contactUs/contactUs-slice';
import updatesSlice from './redux-toolkit/updates/updates-slice';
import commentsSlice from './redux-toolkit/comments/comments-slice';
import peymentsSlice from './redux-toolkit/peyments/updates-slice';
import settingsTransactionsSlice from './redux-toolkit/settings/transactions/transactions-slice';
import myCommentsSlice from './redux-toolkit/settings/myComments/myComments-slice';

import { IAppState } from './redux-toolkit/app/app-types';
import { IAuthState } from './redux-toolkit/auth/auth-types';
import { ISettingsAccountState } from './redux-toolkit/settings/account/account-types';
import { ISettingsNotificationState } from './redux-toolkit/settings/notification/notification-types';
import { IContactUsState } from './redux-toolkit/contactUs/contactUs-types';
import { IUpdatesState } from './redux-toolkit/updates/updates-types';
import { ICommentsState } from './redux-toolkit/comments/comments-types';
import { IPaymentsState } from './redux-toolkit/peyments/updates-types';
import { ISettingsTransactionsState } from './redux-toolkit/settings/transactions/transactions-types';
import { IMyCommentsState } from './redux-toolkit/settings/myComments/myComments-types';

export interface RootState {
  app: IAppState;
  auth: IAuthState;
  settingsAccount: ISettingsAccountState;
  settingsNotification: ISettingsNotificationState;
  settingsTransactionsSlice: ISettingsTransactionsState;
  contactUsSlice: IContactUsState;
  updatesSlice: IUpdatesState;
  commentsSlice: ICommentsState;
  peymentsSlice: IPaymentsState;
  myCommentsSlice: IMyCommentsState;
}

export const rootReducer = combineReducers({
  auth,
  app,
  settingsAccount,
  settingsNotification,
  settingsTransactionsSlice,
  contactUsSlice,
  updatesSlice,
  commentsSlice,
  peymentsSlice,
  myCommentsSlice,
});
