import { lazy } from 'react';

const ProjectPage = lazy(() => import('pages/ProjectPage/ProjectPage'));
const UpdatesPage = lazy(() => import('pages/UpdatesPage/UpdatesPage'));
const CommentsPage = lazy(() => import('pages/CommentsPage/CommentsPage'));
const ContactUsPage = lazy(() => import('pages/ContactUsPage/ContactUsPage'));
const TeamPage = lazy(() => import('pages/TeamPage/TeamPage'));
const CookiePolicy = lazy(() => import('pages/CookiePolicy/CookiePolicy'));
const ResetPassword = lazy(() => import('pages/Auth/ResetPassword/ResetPassword'));
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy/PrivacyPolicy'));

import { Login } from 'pages/Auth/Login';
import { ERoutes } from '../enum/types';
import SignUp from 'pages/Auth/SignUp/SignUp';
import { VerifyEmail } from 'pages/Auth/VerifyEmail/VerifyEmail';
import { ForgotPass } from 'pages/Auth/ForgotPass/ForgotPass';
// import { ResetPassword } from 'pages/Auth/ResetPassword/ResetPassword';
// import CommentsPage from 'pages/CommentsPage/CommentsPage';
// import ProjectPage from 'pages/ProjectPage/ProjectPage';
// import UpdatesPage from 'pages/UpdatesPage/UpdatesPage';
// import TeamPage from 'pages/TeamPage/TeamPage';
// import ContactUsPage from 'pages/ContactUsPage/ContactUsPage';
import { Navigate } from 'react-router-dom';
import { TermsPage } from 'pages/TermsPage/TermsPage';
import { FAQ } from 'pages/FAQ/FAQ';
// import { PrivacyPolicy } from 'pages/PrivacyPolicy/PrivacyPolicy';
// import { CookiePolicy } from 'pages/CookiePolicy/CookiePolicy';
import { Successful } from 'pages/Successful/Successful';
import { Error } from 'pages/Error/Error';
import { CSSTransition } from 'react-transition-group';
import { Page404 } from 'pages/404Page/404Page';
import { BackersPage } from 'pages/BackersPage/BackersPage';

export const PublicRoutes = [
  {
    element: <ProjectPage />,
    path: ERoutes.project,
  },
  {
    element: (
      // <CSSTransition timeout={500} classNames='alert'>
      <UpdatesPage />
      // </CSSTransition>
    ),
    path: ERoutes.updates,
  },
  {
    element: <CommentsPage />,
    path: ERoutes.comments,
  },
  {
    element: <TeamPage />,
    path: ERoutes.team,
  },
  {
    element: <BackersPage />,
    path: ERoutes.backers,
  },
  {
    element: <ContactUsPage />,
    path: ERoutes.contacts,
  },
  {
    element: <Login />,
    path: ERoutes.login,
  },
  {
    element: <SignUp />,
    path: ERoutes.registration,
  },
  {
    element: <VerifyEmail />,
    path: ERoutes.emailVerify,
  },
  {
    element: <ForgotPass />,
    path: ERoutes.forgotPass,
  },
  {
    element: <ResetPassword />,
    path: ERoutes.resetPass,
  },
  {
    element: <TermsPage />,
    path: ERoutes.terms,
  },
  {
    element: <PrivacyPolicy />,
    path: ERoutes.privacyPolicy,
  },
  {
    element: <CookiePolicy />,
    path: ERoutes.cookiePolicy,
  },
  {
    element: <FAQ />,
    path: ERoutes.faq,
  },
  {
    element: <Successful />,
    path: ERoutes.successful,
  },
  {
    element: <Error />,
    path: ERoutes.error,
  },
  {
    element: <Navigate to={ERoutes.project} replace />,
    path: '/',
  },
  {
    path: '*',
    element: <Page404 />,
  },
];
