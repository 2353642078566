import { ForgotForm } from 'components/Forms/ForgotForm/ForgotForm';
import { IForgotForm } from 'components/Forms/ForgotForm/types';
import { ESuccess } from 'enum/types';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { FC, useEffect } from 'react';
import { getStatusPopupsSelector } from 'store/redux-toolkit/auth/app-selector';
import { clearSuccess } from 'store/redux-toolkit/auth/auth-slice';
import { forgotPassword } from 'store/redux-toolkit/auth/auth-thunks';
import { PopupAuth } from 'ui/Popups/PopupAuth';
import img from '../../../assets/images/email-sent.svg';

export const ForgotPass: FC = () => {
  const dispatch = useAppDispatch();
  const statusPopup = useAppSelector(getStatusPopupsSelector);

  const handleForgotPassSubmit = (values: IForgotForm): void => {
    dispatch(forgotPassword(values));
  };

  const closePopup = () => {
    dispatch(clearSuccess());
  };

  useEffect(() => {
    return () => {
      dispatch(clearSuccess());
    };
  }, []);

  return (
    <section className='authorization-section'>
      <div className='authorization'>
        {statusPopup === ESuccess.forgot ? (
          <PopupAuth
            image={img}
            text='An email has been sent to abrams20@gmail.com. Follow the link in the email to change your password.'
            onButton={closePopup}
            textBtn='The letter did not come, send again!'
          />
        ) : (
          <ForgotForm forgotPassSubmit={handleForgotPassSubmit} />
        )}
      </div>
    </section>
  );
};
