import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../../layouts-element/Footer';
import Header from '../../layouts-element/Header';
import { ButtonUp } from 'components/ButtonUp/ButtonUp';
import useScrollPosition from 'hooks/useScrollPosition';

const PublicLayout: FC = () => {
  const scrollPosition = useScrollPosition();

  return (
    <div className='wrapper'>
      {scrollPosition > 600 ? <ButtonUp scrollPosition={scrollPosition} /> : null}
      <Header />
      <main className='main'>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default PublicLayout;
