import classNames from 'classnames';
import { ERoutes } from 'enum/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';

export const SecurityPage: FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <div className='profile-content'>
      <div className='content-block'>
        <h2 className='block-title'>{t('Page.Settings.Security.title')}</h2>
      </div>
      <div className='content-tabs'>
        <div className='tabs'>
          <div className='tabs-btn'>
            <Link
              className={classNames('tabs-btn__item', {
                active: pathname.includes(ERoutes.securityChangePassword),
              })}
              to={ERoutes.securityChangePassword}
            >
              {t('Page.Settings.Security.tab_one')}
            </Link>
            {/* <Link
              className={classNames('tabs-btn__item', {
                active: pathname.includes(ERoutes.securityTwoFactor),
              })}
              to={ERoutes.securityTwoFactor}
            >
              {t('Page.Settings.Security.tab_two')}
            </Link> */}
          </div>
          <div className='tabs-content'>
            <div className='tab-item active'>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      {/* <div className='content-block content-block--type2'>
        <div className='form-confirm form-confirm--767-no-wrap'>
          <div className='block-title-group block-title-group--mb-0'>
            <h2 className='block-subtitle'>Signout everywhere</h2>
            <div className='block-text '>
              <p>
                Sign out everywhere else your PLAYCH account is being used, including all other
                browsers, phones, consoles, or any other devices
              </p>
            </div>
          </div>
          <div className='d-flex form-confirm__right'>
            <button className='button  button--delete' type='button'>
              Sign out other sessions
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
};
