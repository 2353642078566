import { FC, ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useTranslation } from 'react-i18next';

import { FilterTransaction } from 'components/Transactions/FilterTransaction/FilterTransaction';
import { Transactions } from 'components/Transactions/Transactions';
import { getTransactionSelector } from 'store/redux-toolkit/settings/transactions/transactions-selector';
import { getTransactions } from 'store/redux-toolkit/settings/transactions/transactions-thunks';

export const TransactionPage: FC = () => {
  const { t } = useTranslation();
  const [openDesc, setOpenDesc] = useState<number>(0);
  // const [dateTo, setDateTo] = useState<string>('');
  // const [dateFrom, setDateFrom] = useState<string>('');
  // const [price, setPrice] = useState<string>('');
  // const [status, setStatus] = useState<string>('');
  const dispatch = useAppDispatch();
  const transactions = useAppSelector(getTransactionSelector);

  // const onResetFilter = () => {
  //   setDateTo('');
  //   setDateFrom('');
  //   setPrice('');
  //   setStatus('');
  // };

  const onOpenDesc = (id: number) => {
    setOpenDesc(id === openDesc ? 0 : id);
  };

  // const onDateTo = (to: string) => {
  //   setDateTo(to);
  // };

  // const onDateFrom = (from: string) => {
  //   setDateFrom(from);
  // };

  // const onPrice = (event: ChangeEvent) => {
  //   const { value } = event.target as HTMLInputElement;
  //   setPrice(value);
  // };

  useEffect(() => {
    dispatch(
      getTransactions({
        show_count: 10,
        page_number: 1,
      })
    );
  }, []);

  return (
    <div className='profile-content'>
      <div className='content-block'>
        <h2 className='block-title'>{t('Page.Settings.Transaction.transaction_title')}</h2>
        {/* <FilterTransaction
          dateTo={dateTo}
          dateFrom={dateFrom}
          onDateTo={onDateTo}
          onDateFrom={onDateFrom}
          onPrice={onPrice}
          onResetFilter={onResetFilter}
          price={price}
        /> */}
        <Transactions onOpenDesc={onOpenDesc} openDesc={openDesc} transactions={transactions} />
      </div>
    </div>
  );
};
