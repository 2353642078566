import { FC } from 'react';

export const TwoFactor: FC = () => {
  return (
    <div className='content-block'>
      <div className='account-wrap'>
        <div className='block-title-group block-title-group--type2'>
          <h2 className='block-subtitle'>Two-Factor Authentication</h2>
          <div className='block-text block-text--smaller'>
            <p>
              Two-Factor Authentication (2FA) can be used to help protect your account from
              unauthorized access by requiring you to enter a security code when you sign in.
            </p>
          </div>
        </div>
        <h2 className='block-subtitle block-subtitle--small'>Available authentication methods:</h2>
        <div className='security-option-list'>
          {/* <div className='security-option'>
            <div className='security-option__info'>
              <h2 className='block-subtitle block-subtitle--smaller'>
                Two-Factor Authenticator App
              </h2>
              <div className='security-option__text'>
                <p>
                  Use an
                  <a href='/'>Authenticator App </a>
                  as your Two-Factor Authentication (2FA). When you sign in you&apos;ll be required
                  to use the security code provided by your Authenticator App.
                </p>
              </div>
            </div>
            <div className='security-option-switch'>
              <div className='switch'>
                <label className='switch__label'>
                  <input type='checkbox' className='hidden' />
                  <span className='switch__toggler'></span>
                </label>
                <p className='switch__text'>ON</p>
              </div>
            </div>
          </div>
          <div className='security-option'>
            <div className='security-option__info'>
              <h2 className='block-subtitle block-subtitle--smaller'>SMS Authentication</h2>
              <div className='security-option__text'>
                <p>
                  Use your phone as your Two-Factor Authentication (2FA) when you sign in you’ll be
                  required to use the security code we send you via SMS message.
                </p>
              </div>
            </div>
            <div className='security-option-switch'>
              <div className='switch'>
                <label className='switch__label'>
                  <input type='checkbox' className='hidden' />
                  <span className='switch__toggler'></span>
                </label>
                <p className='switch__text'>ON</p>
              </div>
            </div>
          </div> */}
          <div className='security-option'>
            <div className='security-option__info'>
              <h2 className='block-subtitle block-subtitle--smaller'>Email Authentication</h2>
              <div className='security-option__text'>
                <p>
                  Use a security code sent to your email address as your Two-Factor Authentication
                  (2FA). The security code will be sent to the address associated with your account.
                </p>
              </div>
            </div>
            <div className='security-option-switch'>
              <div className='switch'>
                <label className='switch__label'>
                  <input type='checkbox' className='hidden' />
                  <span className='switch__toggler'></span>
                </label>
                <p className='switch__text'>ON</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
