import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import requestService from 'services/http/interceptor/request-service';
import { showError } from 'utils/showError';
import { endpoint } from 'services/endpoint';
import { IProfileError } from '../settings/account/account-types';
import { notificationContainer } from 'ui/notificationContainer';
import { t } from 'i18next';
import { clearErrors, editLike } from './updates-slice';
import { IUpdateThunk } from './updates-types';

export const postUpdatesLike = createAsyncThunk(
  'updates/postUpdatesLike',
  async (payload: IUpdateThunk, thunkApi) => {
    const { paramsApi } = payload;

    try {
      const response = await requestService.post(endpoint.updates.POST_LIKE, paramsApi);
      notificationContainer(t('Update.like_successfully_added'), 'success');
      thunkApi.dispatch(clearErrors());
      thunkApi.dispatch(editLike(paramsApi.post_uuid));
      return response.data;
    } catch (error) {
      const err = error as IProfileError;
      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
      }

      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getListPosts = createAsyncThunk('updates/getListPosts', async (_, thunkApi) => {
  try {
    const response = await requestService.get(endpoint.updates.GET_POSTS);
    thunkApi.dispatch(clearErrors());
    return response.data;
  } catch (error) {
    const err = error as IProfileError;
    if (isAxiosError(error)) {
      if (error.response?.data) {
        showError(error);
      }
    }

    return thunkApi.rejectWithValue(err);
  }
});

export const getMetrics = createAsyncThunk('updates/getMetrics', async (_, thunkApi) => {
  try {
    const response = await requestService.get(endpoint.metrics.METRICS);
    thunkApi.dispatch(clearErrors());
    return response.data;
  } catch (error) {
    const err = error as IProfileError;
    if (isAxiosError(error)) {
      if (error.response?.data) {
        showError(error);
      }
    }

    return thunkApi.rejectWithValue(err);
  }
});
