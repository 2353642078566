import { ERoutes } from 'enum/types';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import logo from '../../assets/images/logo-with-text.svg';
import HeaderContent from 'components/HeaderContent/HeaderContent';
import { Navigation } from 'components/Navigation/Navigation';
import { authPathname } from 'services/constants/authPathname';
import { AfterRegistrationHeader } from 'layouts-element/AfterRegistration/AfterRegistration';
import { useAppSelector } from 'hooks/redux';
import { getIsAuthSelector } from 'store/redux-toolkit/auth/app-selector';
import { Language } from 'ui/Language/Language';
import { listRouts } from 'CONST/routs';
import { LogoSvg } from 'assets/svg-icon';
import { useTranslation } from 'react-i18next';

const Header: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isAuth = useAppSelector(getIsAuthSelector);

  return (
    <>
      {isAuth ? (
        <AfterRegistrationHeader />
      ) : (
        <header id='header-id' className='header'>
          <div className='header__container header__container--full  '>
            <div className='header-left'>
              <Link to='/' className='logo' aria-label='logo'>
                <LogoSvg />
              </Link>
            </div>
            {!authPathname.includes(pathname) && (
              <div className='header-right'>
                <Language />
                <Link
                  style={{ color: 'black' }}
                  to={ERoutes.registration}
                  className='button button--size2'
                >
                  {t('Forms.Sign_up.sign_up')}
                </Link>
                <Link to={ERoutes.login} className='button button--size2 button--type3'>
                  {t('Forms.Login.sign_in')}
                </Link>
              </div>
            )}
          </div>
        </header>
      )}
      {!authPathname.includes(pathname) && listRouts.includes(pathname) && (
        <>
          <HeaderContent />
          <Navigation pathname={pathname} />
        </>
      )}
    </>
  );
};

export default Header;
