import { FC, useEffect } from 'react';
import { onScrollDown } from 'utils/scrollY';

export const TermsPage: FC = () => {
  useEffect(() => {
    onScrollDown();
  }, []);

  return (
    <section className='landing-section landing-section--terms'>
      <div className='container container--big'>
        <p className='terms-last-modify'>Last Modified on 17/01/2024</p>
        <div className='terms-header terms-header-custom'>
          <h2 className='terms-header__title'>Terms and Conditions</h2>
          {/* <div className='select-block select-block--mb-0'>
            <div className='select '>
              <button type='button' className='select__current'>
                <span className='dropdown-icon'>
                  <img src='./images/flag1.png' alt='' />
                </span>
                English
                <span className='select__current-arrow'>
                  <svg
                    width='16'
                    height='8'
                    viewBox='0 0 16 8'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M14.6004 0.958984L9.16706 6.39232C8.52539 7.03399 7.47539 7.03399 6.83372 6.39232L1.40039 0.958984'
                      stroke='#FBFBFB'
                      strokeWidth='1.5'
                      strokeMiterlimit='10'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </span>
              </button>
              <div className='select__drop'>
                <div className='select__drop-scroll'>
                  <div className='select__drop-item'>
                    <ul>
                      <li>
                        <button type='button'>
                          <span className='dropdown-icon'>
                            <img src='./images/flag1.png' alt='' />
                          </span>
                          United States
                        </button>
                      </li>
                      <li>
                        <button type='button'>
                          <span className='dropdown-icon'>
                            <img src='./images/flag2.png' alt='' />
                          </span>
                          Ukrainian
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className='editable-content editable-content-custom'>
          <h3>
            We, PLAYCH or its affiliates (collectively, “PLAYCH,” “we” or “us”), provide online
            features, including text, software, scripts, graphics, audiovisual combinations,
            interactive features, comments, reviews and other materials you may view on, access
            through or contribute to the PLAYCH Services (“Content”) and other products and services
            to you when you visit or shop at the PLAYCH website, use PLAYCH applications for mobile,
            desktop, or use any software or video player platform provided by PLAYCH in connection
            with any of the foregoing (collectively, the “PLAYCH Services”). We provide the PLAYCH
            Services subject to the following conditions.
          </h3>
          <p>
            By accessing, browsing or visiting the PLAYCH website or otherwise using the PLAYCH
            Services, you acknowledge that you have read, understood and agree to these terms and
            conditions (the “Terms of Service”). Please read them carefully. If you do not agree to
            any of these terms, please do not use the PLAYCH Services.
            <br />
            <br />
            We offer a wide range of PLAYCH Services, and sometimes additional guidelines, terms and
            agreements, or product requirements (including age requirements) may apply, which
            requirements will be displayed with the relevant PLAYCH Services and those additional
            terms become part of your agreement with us if you use those PLAYCH Services.
            <br />
            <br />
            Please note that we may, in our sole discretion, update these Terms of Services
            periodically by making such revisions available on the PLAYCH website or otherwise
            providing notice to you. Thus, please revisit this page to stay aware of any changes.
            Your use of the PLAYCH Services constitutes your agreement to these Terms of Service,
            and your continued use of the PLAYCH Services after any revisions to these Terms of
            Service constitutes your agreement to such revisions.
            <br />
            <br />
            With respect to any individual who, or entity which, is subject to a current license
            agreement with PLAYCH, in the event of a conflict between such license agreement and any
            of the Terms of Service, the former shall control.
            <br />
            <br />
            Nothing in these Terms of Service shall be deemed to confer any rights or benefits to
            any third party.
          </p>
          <h3>1. PRIVACY</h3>
          <p>
            Please review our Privacy Policy, which also governs certain aspects of your use of
            PLAYCH Services and is incorporated herein by reference.
          </p>
          <h3>2. ELECTRONIC COMMUNICATIONS</h3>
          <p>
            When you use PLAYCH Services, or send e-mails to us, you are communicating with us
            electronically. You hereby consent to receive communications from us electronically. We
            will communicate with you by e-mail or by posting notices on this website or through
            other means made available by PLAYCH. You agree that all agreements, notices,
            disclosures and other communications that we provide to you electronically satisfy any
            legal requirement that such communications be in writing.
          </p>
          <h3>3. COPYRIGHT</h3>
          <p>
            All of the Content included in or made available through the PLAYCH Services, such as
            text, graphics, logos, button icons, images, audio clips, digital downloads, and data
            compilations are owned by or licensed to PLAYCH or its Content suppliers and protected
            international copyright laws. The compilations of any Content included in or made
            available through any PLAYCH Services are the exclusive property of PLAYCH and are
            protected international copyright laws. Please note that it is PLAYCH’s policy to
            respond to notices of alleged infringement that comply with the Digital Millennium
            Copyright Act (the “DMCA”). Please see Section 12 of these Terms of Service for further
            details. Please note further that we reserve our right to terminate, in our sole
            discretion and without notice, accounts of repeat infringers according to the process
            set forth in the DMCA.
          </p>

          <h3>4. TRADEMARKS</h3>
          <p>
            Graphics, logos, page headers, button icons, scripts, marks, trade names and service
            names included in or made available through the PLAYCH Services are trademarks or trade
            dress of PLAYCH in the United States and other countries. PLAYCH&apos;s trademarks,
            service marks, trade dress and logos may not be used in connection with any product or
            service that is not PLAYCH&apos;s, in any manner that is likely to cause confusion among
            customers, or in any manner that disparages or discredits PLAYCH. All other trademarks
            not owned by PLAYCH that appear in the PLAYCH Services are the property of their
            respective owners, who may or may not be affiliated with, connected to, or sponsored by
            PLAYCH.
          </p>

          <h3>5. PATENTS</h3>
          <p>
            One or more patents owned by PLAYCH may apply to the PLAYCH Services. Portions of the
            PLAYCH Services may operate under license of one or more patents.
          </p>

          <h3>6. LICENSE AND ACCESS</h3>
          <p>
            Subject to your compliance with these Terms of Service and your payment of any
            applicable fees, PLAYCH grants you a limited, non-exclusive, non-transferable, non-sub
            licensable and freely revocable license to access and make personal and non-commercial
            use of the PLAYCH Services. This license does not include any alteration, modification,
            sale/resale, licensing, distribution, reproduction, duplication, copying, public
            performance, display, broadcast, transmission, publication, edition, adaption,
            commercial use or otherwise unauthorised use of any PLAYCH Services or its Content; any
            collection and use of any product listings, descriptions or prices available via the
            PLAYCH Services; any derivative use of any PLAYCH Services or its Content; any
            downloading or copying of account information for the benefit of another merchant; or
            any use of data mining, robots, or similar data gathering and extraction methods or
            tools.
            <br />
            <br />
            The licenses granted by PLAYCH shall terminate if you do not comply with these Terms of
            Service.
            <br />
            <br />
            You may access the Content for your information and personal use solely as intended
            through the provided functionality of the PLAYCH Services and as permitted under these
            Terms of Service and applicable laws and regulations. Without advance written consent of
            PLAYCH you may not (i) frame or utilize framing techniques to enclose any trademark,
            logo, Content or other proprietary information (including, without limitation, images,
            texts, page layouts, or forms) of PLAYCH; and (ii) use any Meta tags or any other
            &rdquo;hidden text&rdquo; utilizing PLAYCH&apos;s name or trademarks. You agree not to
            circumvent, disable or otherwise interfere with security-related features of the PLAYCH
            Services or features that prevent or restrict use or copying of any Content or enforce
            limitations on use of the PLAYCH Services or the Content therein. Neither PLAYCH
            Services nor any part of the Content may be altered, modified, sold, resold, licensed,
            distributed, reproduced, duplicated, copied, publicly performed, displayed, broadcasted,
            transmitted, published, edited, adapted, commercially used or otherwise exploited for
            any purposes other than those explicitly permitted herein without the advance written
            consent of PLAYCH or the respective licensors of the Content.
            <br />
            <br />
            All rights not expressly granted to you in these Terms of Service are reserved and
            retained by PLAYCH or its licensors, suppliers, publishers, rights holders, or other
            Content providers.
            <br />
            <br />
            You understand that when using the PLAYCH Services, you will be exposed to Content from
            a variety of sources, and that PLAYCH is not responsible for the accuracy, usefulness,
            safety, or intellectual property rights of or relating to such Content. You further
            understand and acknowledge that you may be exposed to Content that are inaccurate,
            offensive, indecent or objectionable, and you agree to waive, and hereby do waive, any
            legal or equitable rights or remedies you have or may have against PLAYCH with respect
            thereto, and, agree to indemnify and hold harmless PLAYCH, its owners, operators,
            affiliates, licensors, and licensees to the fullest extent permitted by applicable laws
            and regulations, regarding all matters related to your use of the PLAYCH Services.
          </p>

          <h3>7. YOUR ACCOUNT</h3>
          <p>
            Anyone over the age of 13 may create a user account. If you are under 18 years of age,
            you may use the PLAYCH Services under the supervision of a parent or guardian. By
            downloading, installing or otherwise using the PLAYCH Services, you represent that you
            are either at least 18 years of age, or an emancipated minor, or possess legal parental
            or guardian consent, and that you are fully able and competent to enter into the terms,
            conditions, obligations, affirmations, representations, and warranties set forth in
            these Terms of Service, and to abide by and comply with these Terms of Service. We
            reserve the right to refuse service, terminate accounts, remove or edit Content, or
            cancel orders in our sole discretion, in case of the use of the PLAYCH Services in
            violation of the foregoing.
            <br />
            <br />
            All information that you supply upon creating your user account must be accurate and
            complete. We reserve the right to reject and delete any user name or password which
            violates the rights of any person or entity, in any way, direct or indirect, or which
            contains offensive or explicit remarks, including personal, political, religious, racial
            and sexual contents. You are responsible for maintaining the confidentiality of your
            account and password and for restricting access to your computer, and you agree to
            accept responsibility for all activities that occur on your account or password. You
            must notify PLAYCH immediately off any breach of security or unauthorised use of your
            account. Although we will not be liable for your losses caused by any unauthorised use
            of your account, you may be liable for the losses of PLAYCH or others due to such
            unauthorised use.
          </p>

          <h3>8. CONTENT YOU POST ON THE SITE</h3>
          <div>
            You are responsible for all Content that you post on or transmit through the PLAYCH
            Services, including your user comments. You may not post any Content which:
            <br />
            <br />
            <ul className='list-terms'>
              <li>
                Infringes the copyright, trademark, patent right or other proprietary right of any
                person or that is used without the permission of the owner;
              </li>
              <li>You know is inaccurate;</li>
              <li>Is pornographic, sexually explicit or obscene;</li>
              <li>Exploits children or minors;</li>
              <li>Violates the rights of privacy or publicity of any person;</li>
              <li>Is libellous, slanderous or defamatory;</li>
              <li>
                Contains any personally-identifying information about any person without their
                consent or about any person who is a minor;
              </li>
              <li>
                May be deemed generally offensive to the online community, including blatant
                expressions of bigotry, prejudice, racism, hatred or profanity;
              </li>
              <li>
                Includes advertisements, promotions, solicitations or offers to sell any goods or
                services for any commercial purpose; or promotes or provides instructional
                information about illegal or illicit activities; or contains software viruses or any
                other computer code, files or programs designed to destroy, interrupt or otherwise
                limit the functionality of any computer software, computer hardware or other
                equipment.
              </li>
            </ul>
            You understand and acknowledge that we do not guarantee any confidentiality with respect
            to any Content you submit. You shall be solely responsible for your own Content and the
            consequences of submitting and publishing your Content on the PLAYCH Services. By
            uploading and publishing your Content, you affirm, represent and warrant that (i) you
            own or have the necessary licenses, rights, consents and permissions to publish the
            Content you submit; (ii) you grant PLAYCH and our affiliates and partners a
            nonexclusive, perpetual, irrevocable, worldwide, sub licensable, royalty-free license to
            use, store, display, publish, transmit, transfer, distribute, reproduce, rearrange,
            edit, modify, aggregate, create derivative works of, publicly perform or otherwise
            exploit the Content that you submit to or through the PLAYCH Services for any purpose;
            (iii) your Content do not and will not (a) infringe, violate or misappropriate any
            third-party right, including any copyright, trademark, patent, trade secret, moral
            right, privacy right, right of publicity, or any other intellectual property or
            proprietary right, (b) slander, defame, or libel any other person; (iv) your Content do
            not contain any viruses, adware, malware, spyware, worms, or other malicious code; and
            (v) unless you have received prior written authorisation, your Content specifically does
            not contain any confidential information of PLAYCH or third parties.
            <br />
            <br />
            Users violating these third-party rights may be subject to criminal and civil liability.
            We reserve the right to remove, in our sole discretion, any Content that violates these
            Terms of Service or that we determine is otherwise not appropriate for the PLAYCH
            Services.
            <br />
            <br />
            You also hereby grant each user of the PLAYCH Services a perpetual, personal,
            non-commercial, non-transferable, non-exclusive license to access your Content through
            the PLAYCH Services, and to use, reproduce, distribute, display and perform such Content
            as permitted through the functionality of the PLAYCH Services and under these Terms of
            Service.
            <br />
            <br />
            You also consent to the display of advertising within, adjacent to, or on top of, any of
            your Content.
            <br />
            <br />
            We do not endorse any Content submitted to the PLAYCH Services by any user or other
            licensor, or any opinion, recommendation, or advice expressed therein, and we expressly
            disclaim any and all liability in connection with the Content. We do not permit
            copyright-infringing activities and infringement of intellectual property rights on the
            PLAYCH Services, and we reserve the right to remove the Content that infringe on
            another&apos;s intellectual property rights, without prior notice at any time in its
            sole discretion.
          </div>
          <h3>9. YOUR USE OF THE SITE</h3>
          <div>
            All Content available through the PLAYCH website are protected by our copyrights or
            trademarks and those of our licensors. You may not use, store, display, publish,
            transmit, distribute, modify, reproduce, create derivative works of or in any way
            exploit any of the Content, in whole or in part, outside of the specific usage rights
            granted to you by PLAYCH in these Terms of Service.
            <br />
            <br />
            You may not use the PLAYCH Services to do any of the following:
            <ul className='list-terms'>
              <li>Harass or advocate harassment of another person or entity;</li>
              <li>
                Perform any activities that violate any state, local, federal, or international laws
                or regulations;
              </li>
              <li>
                Provide resources to or otherwise support any organisation(s) designated by the
                United States government as a foreign terrorist organisation under section 219 of
                the Immigration and Nationality Act;
              </li>
              <li>
                Impersonate any person or entity or misrepresent in any way your affiliation with a
                person or entity;
              </li>
              <li>Transmit unsolicited mass mailings or &apos;spam;&apos;</li>
              <li>
                Collect or store any information about other users or members, other than in the
                normal course of using the website for its intended purpose of facilitating
                voluntary communication among users;
              </li>
              <li>
                Transmit any virus, worm, defect, Trojan horse or similar destructive or harmful
                item.
              </li>
              <li>
                Use it in any manner that could damage, disable, overburden, disrupt or impair the
                website or our network or interfere with any other party&apos;s use and enjoyment of
                the website;
              </li>
              <li>Modify, adapt, translate or reverse engineer the website;</li>
              <li>
                Use any robot, spider, website search/retrieval application, or other device to
                retrieve or index any portion of the website (though we grant the operators of
                public search engines permission to use spiders to copy materials from the website
                for the sole purpose of creating publicly available searchable indices of the
                Content on the website that link back to the website for the full text of the
                Content);
              </li>
              <li>Frame or mirror the website or reformat it in any way;</li>
              <li>
                Attempt to circumvent, disable or otherwise interfere with any security-related
                features of the PLAYCH Services or features that (i) prevent or restrict use or
                copying of the Content or (ii) enforce limitations on use of the PLAYCH Services or
                the content accessible via PLAYCH Services; or create user accounts using any
                automated means or under false pretences
              </li>
            </ul>
          </div>

          <h3>10. THIRD-PARTY SITES, PRODUCTS, AND SERVICES</h3>
          <p>
            PLAYCH Services provide access and contain links to third-party websites and services.
            Such links to third-party websites and services are solely for the convenience of users,
            and we do not endorse such third-party websites and services or the information,
            materials, products or services contained on or accessible through such websites and
            services. Further, we have no control over and will not and cannot censor or edit the
            content of any third-party website. Thus, we are not responsible for the activities of
            any third-party websites and assume no responsibility for the content, privacy policies
            or practices of any of such third-party websites. Your use of those websites and
            services is subject to the terms and conditions of those websites. By using PLAYCH
            Services, you expressly relieve PLAYCH from any and all liabilities arising from your
            use of any third-party website. Accordingly, we encourage you to be aware when you leave
            the PLAYCH Services and to read the terms and conditions and privacy policy of each
            other website that you visit.
            <br />
            <br />
            We may permit you to register for and log onto the PLAYCH Services via certain
            third-party social networks. If you log in via such social networks, the profile
            information connected to the account you use to log into the PLAYCH Services may be used
            by us to provide support for your account. You also acknowledge and agree that we may
            publish information regarding your use of the PLAYCH Services to and in connection with
            any such third-party social networks through which you use the PLAYCH Services.
          </p>

          <h3>11. EMBEDDABLE ITEMS</h3>
          <p>
            We may make available charts, graphics or other items which you can embed on your own
            website. If you use any of these embeddable items, you must include a prominent link
            back to the PLAYCH website on the pages containing the embeddable item and you may not
            modify, build upon or block any portion of the embeddable item in anyway or present it
            in any manner that is false or misleading. We retain the right to revoke your permission
            to use any such items at any time for any reason in our sole discretion.
          </p>

          <h3>12. INTELLECTUAL PROPERTY RIGHTS POLICY</h3>
          <div>
            We respect the rights of intellectual property holders. If you believe that any of the
            Content of the PLAYCH Services violates these Terms of Service or your intellectual
            property rights, you can report such violation to us. In the case of an alleged
            infringement, please provide the following information:
            <br />
            <br />
            <ul className='list-terms'>
              <li>
                A description of the copyrighted work or other intellectual property that you claim
                has been infringed;
              </li>
              <li>
                A description of where the material that you claim is infringing is located on the
                website (including the exact URL);
              </li>
              <li>
                An address, a telephone number, and an e-mail address where we can contact you;
              </li>
              <li>
                A statement that you have a good-faith belief that the use is not authorised by the
                copyright or other intellectual property rights owner, by its agent, or by law;
              </li>
              <li>
                A statement by you under penalty of perjury that the information in your notice is
                accurate and that you are the copyright or intellectual property owner or are
                authorised to act on the owner&apos;s behalf;
              </li>
              <li>And your electronic or physical signature.</li>
            </ul>
            We may request additional information before we remove allegedly infringing material.
            You may report a copyright violation by providing the above information to:{' '}
            <a href='mailto:support@playch.tv' target='_blank' rel='noreferrer'>
              support@playch.tv
            </a>{' '}
            We will terminate the user account of any user who repeatedly violates our intellectual
            property policies, at our sole discretion.
          </div>

          <h3>13. OUR RIGHTS AND RESPONSIBILITIES</h3>
          <div>
            We maintain the right to do any of the following at any time, with or without prior
            notice, in our sole discretion, for any or no reason:
            <br />
            <br />
            <ul className='list-terms'>
              <li>
                Restrict, suspend, or terminate your access to all or any part of the PLAYCH
                Services;
              </li>
              <li>Change, suspend, or discontinue all or any part of the PLAYCH Services;</li>
              <li>Refuse, move, or remove any Content;</li>
              <li>Refuse to register any user name that we may deem offensive; and</li>
              <li>
                Establish general practices, fees and policies concerning the PLAYCH Services.
              </li>
            </ul>
            We do not make any representations about the accuracy of the Content posted on the
            PLAYCH Services, whether posted by us, our users or third parties. All information and
            services provided on the PLAYCH Services are offered &ldquo;as-is&ldquo; without any
            implied warranty, and you should not rely on the information presented on the PLAYCH
            Services without discretion. Your use of the PLAYCH Services is at your own risk.
            <br />
            <br />
            We disclaim any responsibility for inaccuracies on the PLAYCH Services. We are not
            responsible for any loss, harm, error or omission that may result from the use of the
            PLAYCH Services we provide or for any service failure or interruption, regardless of the
            cause.
          </div>

          <h3>14. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h3>
          <p>
            PLAYCH AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) AND OTHER
            SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE PLAYCH SERVICES ARE
            PROVIDED BY PLAYCH ON AN &ldquo;AS IS&ldquo; AND &ldquo;AS AVAILABLE&ldquo; BASIS,
            UNLESS OTHERWISE SPECIFIED IN WRITING. WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY
            KIND, EXPRESSED OR IMPLIED, AS TO THE OPERATION OF PLAYCH, OR THE INFORMATION, CONTENT,
            MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE
            AVAILABLE TO YOU THROUGH THE PLAYCH SERVICES, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU
            EXPRESSLY AGREE THAT YOUR USE OF THE PLAYCH SERVICES IS AT YOUR SOLE RISK.
            <br />
            <br />
            TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESSED
            OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND
            FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT THAT OUR SERVICES, INFORMATION,
            CONTENTS, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR
            OTHERWISE MADE AVAILABLE TO YOU THROUGH PLAYCH SERVICES, PLAYCH&lsquo;S SERVERS OR
            ELECTRONIC COMMUNICATIONS SENT FROM PLAYCH WILL BE(I) ACCURATE OR COMPLETE, (II)
            UNINTERRUPTED, OR (III) FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
            <br />
            <br />
            WE WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES
            ARISING FROM THE USE OF PLAYCH, THE SITE, OR FROM ANY INFORMATION, CONTENT, MATERIALS,
            PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE
            TO YOU THROUGH THE PLAYCH SERVICES, WHETHER CLAIMS FOR SUCH DAMAGES ARE BASED ON
            WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE COMPANY IS
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            <br />
            <br />
            WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
            SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES OR ANY HYPERLINKED
            SERVICES OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO
            OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY
            PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH
            ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
            WHERE APPROPRIATE.
            <br />
            <br />
            YOU UNDERSTAND AND AGREE THAT YOU USE, ACCESS, DOWNLOAD OR OTHERWISE OBTAIN INFORMATION,
            MATERIALS, OR DATA THROUGH PLAYCH SERVICES AT YOUR OWN DISCRETION AND RISK AND THAT YOU
            WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER
            SYSTEM) OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF SUCH MATERIALS OR DATA.
            <br />
            <br />
            YOU SPECIFICALLY ACKNOWLEDGE THAT PLAYCH SHALL NOT BE LIABLE FOR CONTENT OR THE
            DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY AND THAT THE RISK OF HARM
            OR DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU.
            <br />
            <br />
            THE PLAYCH SERVICES ARE CONTROLLED AND OFFERED BY PLAYCH FROM ITS FACILITIES IN THE
            UNITED STATES OF AMERICA. PLAYCH MAKES NO REPRESENTATIONS THAT THE PLAYCH SERVICES IS
            APPROPRIATE OR AVAILABLE FOR USE IN OTHER LOCATIONS. THOSE WHO ACCESS OR USE THE PLAYCH
            SERVICES FROM OTHER JURISDICTIONS DO SO AT THEIR OWN RISK OF VOLITION OF AND ARE
            RESPONSIBLE FOR COMPLIANCE WITH LOCAL LAW.
            <br />
            <br />
            IN NO EVENT WILL THE TOTAL LIABILITY OF PLAYCH, ITS AFFILIATES, CONTRACTORS, EMPLOYEES,
            AGENTS OR THIRD-PARTY PARTNERS, LICENSORS, OR SUPPLIERS TO YOU FOR ALL DAMAGES, LOSSES
            AND CAUSES OF ACTION ARISING OUT OF OR RELATING TO THESE TERMS OF SERVICE OR YOUR USE OF
            THE PLAYCH SERVICES OR YOUR INTERACTION WITH OTHER PLAYCH USERS (WHETHER IN CONTRACT,
            TORT, OR OTHERWISE), EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THE PLAYCH
            SERVICES DURING TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE DATE OF THE CLAIM OR ONE
            HUNDRED US DOLLARS, WHICHEVER IS GREATER.
            <br />
            <br />
            YOU AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE PLAYCH SERVICES MUST
            COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF
            ACTION IS PERMANENTLY BARRED.
            <br />
            <br />
            CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
            LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
            DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE
            ADDITIONAL RIGHTS.
          </p>

          <h3>15. DISPUTES</h3>
          <p>
            Any dispute or claim relating in any way to your use of the PLAYCH Services shall be
            submitted to the exclusive jurisdiction of the federal and you consent and submit to
            personal jurisdiction in those courts.
            <br />
            <br />
            You agree that any dispute resolution proceedings will be conducted only on an
            individual basis and not in a class, consolidated or representative action. You waive
            any right to a jury trial.
          </p>

          <h3>16. SITE POLICIES, MODIFICATION, AND SEVER ABILITY</h3>
          <p>
            These Terms of Service, together with the Privacy Policy and any other legal notices
            published by PLAYCH on the PLAYCH Services, shall constitute the entire agreement
            between you and PLAYCH concerning the PLAYCH Services.
            <br />
            <br />
            We reserve the right to amend these Terms of Service at any time, and it is your
            responsibility to review these Terms of Service for any changes. Your use of the PLAYCH
            Services following any amendment of these Terms of Service will signify your assent to
            and acceptance of its revised terms.
            <br />
            <br />
            These Terms of Service, and any rights and licenses granted hereunder, may not be
            transferred or assigned by you, but may be assigned by PLAYCH without restriction.
            <br />
            <br />
            If any provision of these Terms of Service is deemed invalid, void, or for any reason
            unenforceable, by a court of competent jurisdiction, the invalidity of such provision
            shall not affect the validity of the remaining provisions of these Terms of Service,
            which shall remain in full force and effect.
            <br />
            <br />
            No waiver of any term of these Terms of Service shall be deemed a further or continuing
            waiver of such term or any other term, and PLAYCH&apos;s failure to assert any right or
            provision under these Terms of Service shall not constitute a waiver of such right or
            provision.
          </p>

          <h3>17. CONSEQUENCES OF VIOLATION OF THE TERMS</h3>
          <p>
            To the extent permitted by applicable laws, you agree to indemnify, defend and hold
            PLAYCH and its officers, directors, employees, agents, and affiliates harmless from and
            against any and all claims, damages, obligations, liabilities, losses, costs, and
            expenses (including attorneys&apos; fees) incurred by PLAYCH arising from: (i) your use
            of and access to the PLAYCH Services; (ii) your violation of any term of these Terms of
            Service; (iii) your violation of any third-party right, including without limitation any
            copyright, property, or privacy right; or (iv) any claim that your Content caused damage
            to a third party. This defense and indemnification obligation will survive these Terms
            of Service and your use of the PLAYCH Services.
            <br />
            <br />
            We reserve the right to terminate your access to the PLAYCH Services and/or these Terms
            of Service if you fail to abide by these Terms of Service. We reserve the right to
            decide whether Content submitted by you violates these Terms of Service. We may at any
            time, without prior notice and in our sole discretion, remove such Content and/or
            terminate a user&apos;s account for submitting such material in violation of these Terms
            of Service. We also reserve the right to inform law enforcement authorities of any
            potential illegal activities and to provide them with all information about the account
            through which such activities occurred, as further described in our Privacy Policy.
          </p>

          <h3>18. OPERATION OF SITE</h3>
          <p>This website is operated by: PLAYCH</p>
        </div>
      </div>
    </section>
  );
};
