import { SearchBigSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import moment from 'moment';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IPayments } from 'store/redux-toolkit/settings/transactions/transactions-types';

interface IProps {
  onOpenDesc: (id: number) => void;
  openDesc: number;
  transactions: Array<IPayments> | null;
}

export const TableBody: FC<IProps> = ({ onOpenDesc, openDesc, transactions }) => {
  const { t } = useTranslation();

  return transactions?.length ? (
    <>
      {transactions?.map((transaction) => (
        <div key={transaction.id} className='table-body'>
          <div className={classNames('tr-with-drop', { active: openDesc === transaction.id })}>
            <div className='tr'>
              <div className='td'>
                <p className='td-hidden-name'>
                  {t('Page.Settings.Transaction.transaction_t_date')}
                </p>
                <p>{moment(transaction.created_at).format('MM.DD.YY')}</p>
              </div>
              <div className='td'>
                <p className='td-hidden-name'>
                  {t('Page.Settings.Transaction.transaction_t_description')}
                </p>
                <p>{t('Page.Project.back_this_project')}</p>
              </div>
              <div className='td'>
                <p className='td-hidden-name'>
                  {t('Page.Settings.Transaction.transaction_t_price')}
                </p>
                <p>{`${transaction.currency.toUpperCase()} ${transaction.amount}`}</p>
              </div>
              <div className='td'>
                <p className='td-hidden-name'>
                  {t('Page.Settings.Transaction.transaction_t_status')}
                </p>
                <p className='td-status td-status--success'>{transaction.status}</p>
              </div>
              <button onClick={() => onOpenDesc(transaction.id)} className='tr-drop-btn'>
                <svg
                  width='10'
                  height='6'
                  viewBox='0 0 10 6'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.46973 1.13965L4.99973 4.65965L8.52973 1.13965'
                    stroke='#8B8B8B'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </button>
            </div>
            <div className='tr-details-list'>
              <div className='tr-details'>
                <div className='td'>
                  <p>{`${t('Page.Settings.Transaction.transaction_t_order_id')} ${
                    transaction.id
                  }`}</p>
                </div>
                <div className='td'>
                  <p>{`${transaction.currency.toUpperCase()} ${transaction.amount}`}</p>
                </div>
                {/* <div className='td'>
                  <button className='button button--size-td' type='button'>
                    Receipt
                  </button>
                </div> */}
              </div>
              {/* <div className='tr-details'>
                <div className='td'>
                  <p className='td-value td-value--fw-700 td-value--big'>Total</p>
                </div>
                <div className='td'>
                  <p className='td-value td-value--fw-700 td-value--big'>{`USD ${5.00}`}</p>
                </div>
              </div> */}
            </div>
            <div className='tr-mob-drop-btn'>
              <button className='link'>Show more details</button>
            </div>
          </div>
        </div>
      ))}
    </>
  ) : (
    <div className='search-flex'>
      <SearchBigSvg />
    </div>
  );
};
