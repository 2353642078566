import { store } from 'store/store';
import { IAxiosError } from './types';
import { refreshToken } from 'store/redux-toolkit/auth/auth-thunks';

const onError = (error: IAxiosError) => {
  const status = error.response ? error.response.status : null;
  const errorResponse: any = error.config;

  if (status === 401 && !errorResponse._retry) {
    console.log('start');
    store.dispatch(refreshToken({ token: localStorage.getItem('refresh_token') || '' }));
  }
  return Promise.reject(error);
};
export default onError;
