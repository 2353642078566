import { FC } from 'react';

export const Filter: FC = () => {
  return (
    <div className='table-filters table-filters--transactions-history'>
      <div className='filter'>
        <p className='input-name'>From</p>
        <div className='input-wrapper'>
          <input className='input-item input-item--size2' type='text' placeholder='DD.MM.YY' />
        </div>
      </div>
      <div className='filter'>
        <p className='input-name'>To</p>
        <div className='input-wrapper'>
          <input className='input-item input-item--size2 ' type='text' placeholder='DD.MM.YY' />
        </div>
      </div>
      <div className='table-filters__btn'>
        <button className='button button--size2' type='button'>
          Reset
        </button>
      </div>
    </div>
  );
};
