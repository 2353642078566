/* eslint-disable react/no-unknown-property */
import { MapSvg } from 'assets/svg-icon';
import { FC, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import img1 from '../../assets/images/intro-slide1.jpg';
import img1_webp from '../../assets/images/intro-slide1_1.webp';
import img2 from '../../assets/images/intro-slide2.jpg';
import img2_webp from '../../assets/images/intro-slide2_1.webp';
import img3 from '../../assets/images/intro-slide3.jpg';
import img3_webp from '../../assets/images/intro-slide3_1.webp';
import img4 from '../../assets/images/intro-slide4.jpg';
import img4_webp from '../../assets/images/intro-slide4_1.webp';
import img5 from '../../assets/images/intro-slide5.jpg';
import img5_webp from '../../assets/images/intro-slide5.webp';
import SlideNextButton from './SlideNextButton/SlideNextButton';
import SliderPreviousButton from './SliderPreviousButton/SliderPreviousButton';
import { Autoplay, EffectFade, type Swiper as SwiperRef } from 'swiper';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

const webpArr = [img1_webp, img2_webp, img3_webp, img4_webp, img5_webp];

export const SwiperComponent: FC = () => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperRef | null>(null);
  const [index, setIndex] = useState<number>(0);
  const [len, setLen] = useState<number>(3);

  const onAutoplayTimeLeft = () => {
    setIndex(swiperInstance?.activeIndex || 0);
    setLen(swiperInstance ? swiperInstance.slides.length - 1 : 1);
  };

  return (
    <div className='intro-media'>
      <div className='swiper-container intro-slider'>
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          autoHeight={true}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          modules={[EffectFade, Autoplay]}
          effect='flip'
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          onAutoplayTimeLeft={() => swiperInstance?.activeIndex !== index && onAutoplayTimeLeft()}
          className='swiper-wrapper'
        >
          {[img1, img2, img3, img4, img5].map((image, index) => (
            <SwiperSlide key={index} className='swiper-slide'>
              <div className='intro-slider-item'>
                <picture>
                  <source type='image/webp' srcSet={webpArr[index]} />
                  <source type='image/png' srcSet={image} />
                  {
                    //@ts-ignore
                    <img fetchPriority='high' src={webpArr[index]} alt='slide' />
                  }
                </picture>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className='intro-media-panel'>
        <div className='slider-button-wrap'>
          <SliderPreviousButton swiperInstance={swiperInstance} setIndex={setIndex} index={index} />
          <SlideNextButton
            swiperInstance={swiperInstance}
            setIndex={setIndex}
            index={index}
            setLen={setLen}
            len={len}
          />
        </div>
      </div>
    </div>
  );
};
