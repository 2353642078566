import { BurgerCloseSvg, BurgerSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { setBurger } from 'store/redux-toolkit/app/app-slice';
import { onScrollDown } from 'utils/scrollY';

interface IProps {
  activeBurger: boolean;
}

export const BtnBurger: FC<IProps> = ({ activeBurger }) => {
  const dispatch = useDispatch();

  const onBurger = () => {
    onScrollDown();
    dispatch(setBurger(!activeBurger));

    if (!activeBurger) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  };

  return (
    <button
      onClick={onBurger}
      type='button'
      className={classNames('nav-btn', { active: activeBurger })}
    >
      <BurgerSvg />
      <BurgerCloseSvg />
    </button>
  );
};
