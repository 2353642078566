import { FC } from 'react';
import img404 from '../../assets/images/404.png';
// import logo from '../../assets/images/logo-with-text.svg';
import { Link } from 'react-router-dom';
import { Example } from 'components/Example/Example';
import { LogoSvg } from 'assets/svg-icon';

export const Page404: FC = () => {
  return (
    <div className='not-found-section'>
      {/* <Example /> */}
      <header id='header-id' className='header-404'>
        <div className='header__container header__container--full  '>
          <div className='header-left'>
            <Link to='/' className='logo'>
              <LogoSvg />
            </Link>
          </div>
        </div>
      </header>
      <div className='container'>
        <div className='not-found-img'>
          <img src={img404} alt='404' />
        </div>
        <div className='not-found'>
          <div className='not-found__info'>
            <h2 className='block-subtitle block-subtitle--fw-600 not-found__info-title'>
              Oh no… Look like you are lost, kap!
            </h2>
            <div className='info-text info-text--type2'>
              <p>
                {`We searched everywhere but couldn't find what you're looking for. Let's find a
                better place for you to go.`}
              </p>
            </div>
          </div>
          <Link to='/' className='button button--width-auto'>
            Back to Homepage
          </Link>
        </div>
      </div>
    </div>
  );
};
