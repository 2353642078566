import { ArrowLeftSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { FC } from 'react';
import { IPropsSwiperBtn } from '../types';

const SliderPreviousButton: FC<IPropsSwiperBtn> = ({ swiperInstance, index, setIndex }) => (
  <button
    onClick={() => {
      swiperInstance?.slidePrev();
      setIndex(swiperInstance?.activeIndex || 0);
    }}
    className={classNames('slider-button slider-button--prev', {
      'swiper-button-disabled': !index,
    })}
    type='button'
    aria-label='Slider Previous Button'
  >
    <ArrowLeftSvg />
  </button>
);

export default SliderPreviousButton;
