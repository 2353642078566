import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from 'enum/types';
import { getNotification, postNotification } from './notification-thunks';
import { INotificationRespone, ISettingsNotificationState } from './notification-types';

const initialState: ISettingsNotificationState = {
  notification: null,
  loadingState: LoadingStatus.NEVER,
  success: '',
  error: '',
  errors: null,
  popup: '',
};

export const settingsNotificationSlice = createSlice({
  name: 'settingsNotification',
  initialState,
  reducers: {
    resetAuthState: () => initialState,
    clearErrors: (state) => {
      state.error = '';
      state.errors = null;
    },
    clearSuccess: (state) => {
      state.success = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotification.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(
        getNotification.fulfilled,
        (state, { payload }: PayloadAction<INotificationRespone>) => {
          state.loadingState = LoadingStatus.SUCCESS;
          state.notification = payload;
        }
      )
      .addCase(postNotification.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(postNotification.fulfilled, (state, { payload }: PayloadAction<boolean>) => {
        state.loadingState = LoadingStatus.SUCCESS;
        if (state.notification) state.notification.receive_email_notifications = payload;
      });
  },
});

export const { resetAuthState, clearErrors, clearSuccess } = settingsNotificationSlice.actions;
export default settingsNotificationSlice.reducer;
