import { ERoutes } from 'enum/types';
import { useAppDispatch } from 'hooks/redux';
import { FC, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { confirmEmail } from 'store/redux-toolkit/auth/auth-thunks';
import images from '../../../assets/svg-icon/success-auth.svg';

export const VerifyEmail: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  useEffect(() => {
    dispatch(confirmEmail(token?.replace(':', '') || ''));
  }, []);

  return (
    <section className='authorization-section'>
      <form className='form form--authorization'>
        <div className='form-icon'>
          <img src={images} alt='Successful' />
        </div>
        <h2 className='form-title'>Successful</h2>
        <div className='form__body'>
          <div className='form-text form-text--ta-center'>
            <p>
              Congratulation! Your email has already been confirmed. You can login to the PLAYCH now
            </p>
          </div>
        </div>
        <div className='form__extra'>
          <Link to={ERoutes.login} className='button button--full-width'>
            Log in
          </Link>
        </div>
      </form>
    </section>
  );
};
