import { CloseToastifySvg, RangeLargeSvg, RangeSmallSvg } from 'assets/svg-icon';
import { FC, useState, ChangeEvent, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Cropper from 'react-easy-crop';
// eslint-disable-next-line import/no-unresolved
import { Point, Area } from 'react-easy-crop/types';
import getCroppedImg from 'utils/cropImage';
type TProps = {
  image: string | null;
  onSaveImage: (value: Blob) => void;
  onClosePopup: () => void;
};

export const PopupAddPhoto: FC<TProps> = ({ image, onSaveImage, onClosePopup }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [rotation, setRotation] = useState(0);
  const [inputKey, setInputKey] = useState<string>('');
  const [croppedImage, setCroppedImage] = useState<string>('');
  const { t } = useTranslation();

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage: any = await getCroppedImg(image, croppedAreaPixels, rotation);
      onSaveImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const resetsFileInput = () => {
    setCroppedImage('');
    onClosePopup();
  };

  return (
    <div className='popup-window'>
      <div className='popup-window__inside'>
        <div className='popup popup--width-460'>
          <button onClick={onClosePopup} className='popup__close'>
            <CloseToastifySvg />
          </button>
          <div className='popup-header'>
            <h2 className='popup-title'>{t('Page.Settings.Account.avatar_title_add')}</h2>
          </div>
          <div className='popup-body'>
            <div className='change-photo-box change-photo-box-custom'>
              <Cropper
                image={image || ''}
                crop={crop}
                zoom={zoom}
                cropSize={{ width: 256, height: 256 }}
                cropShape='round'
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                restrictPosition={true}
                showGrid={false}
                onRotationChange={setRotation}
              />
            </div>
            <div className='change-photo-size'>
              <button
                onClick={() => zoom > 1 && setZoom(zoom - 1)}
                className='change-photo-size__btn'
              >
                <RangeSmallSvg />
              </button>
              <input
                type='range'
                min='1'
                max='3'
                step='any'
                value={zoom}
                onChange={({ target }: ChangeEvent<HTMLInputElement>) => setZoom(+target.value)}
                className='slider-line'
              />
              <button
                type='button'
                aria-label='Range'
                onClick={() => zoom < 3 && setZoom(zoom + 1)}
                className='change-photo-size__btn change-photo-size__btn--big'
              >
                <RangeLargeSvg />
              </button>
            </div>
          </div>
          <div className='popup-footer'>
            <div className='form-submit form-submit--mob-column'>
              <button
                onClick={resetsFileInput}
                className='button button--type3 button--full-width'
                type='button'
              >
                {t('Global.cancel')}
              </button>
              <button
                onClick={showCroppedImage}
                className='button button--full-width'
                type='button'
              >
                {t('Global.save')}
              </button>
            </div>
          </div>
          <img src={croppedImage} alt='' />
        </div>
      </div>
    </div>
  );
};
