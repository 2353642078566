import { AxiosError, isAxiosError } from 'axios';
import { t } from 'i18next';
import { notificationContainer } from '../ui/notificationContainer';
import { capitalize } from './strings';
import { isDevelopment } from './isDevelopment';

// Helper functions
function getResponseErrorMsg(error: any) {
  if (!error.response?.data) {
    return null;
  }

  const { message, errors } = error.response.data;

  if (message && typeof message === 'string') {
    return message;
  }

  if (errors !== null) {
    if (errors.length) {
      return errors.join();
    }
    const firstError: any = Object.values(errors)[0];
    return firstError.join();
  }

  return null;
}

function formatMsgToHuman(message: string) {
  return capitalize(t(message));
}

export const showError = (error: AxiosError) => {
  if (!isAxiosError(error)) {
    return;
  }
  let humanMsg = '';
  const responseMsg = error?.response?.data?.errors?.[0].Msg || getResponseErrorMsg(error);
  if (responseMsg) {
    const translatedMsg = String(t(`Errors.${responseMsg}`, { notFound: '' }));
    if (isDevelopment()) humanMsg = translatedMsg || formatMsgToHuman(responseMsg);
  } else {
    humanMsg = String(t('Errors.something_went_wrong'));
  }
  switch (error.response?.status) {
    case 429: // Too Many Requests
      notificationContainer(String(t(`Errors.too_many_requests`)), 'error');
      break;
    case 500: // Internal Server Error
      notificationContainer(String(t(`Errors.internal_server_error`)), 'error');
      break;
    default:
      humanMsg && notificationContainer(humanMsg, 'error');
  }
};
