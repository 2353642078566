import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from 'enum/types';
import { ISettingsTransactionsState, ITransactionsResponse } from './transactions-types';
import { getTransactions } from './transactions-thunks';

const initialState: ISettingsTransactionsState = {
  transactions: null,
  loadingState: LoadingStatus.NEVER,
  success: '',
  error: '',
  errors: null,
  popup: '',
};

export const settingsTransactionsSlice = createSlice({
  name: 'settingsTransactions',
  initialState,
  reducers: {
    resetTransactionsState: () => initialState,
    clearErrors: (state) => {
      state.error = '';
      state.errors = null;
    },
    clearSuccess: (state) => {
      state.success = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransactions.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(
        getTransactions.fulfilled,
        (state, { payload }: PayloadAction<ITransactionsResponse>) => {
          state.loadingState = LoadingStatus.SUCCESS;
          state.transactions = payload.data.payments;
        }
      );
  },
});

export const { resetTransactionsState, clearErrors, clearSuccess } =
  settingsTransactionsSlice.actions;
export default settingsTransactionsSlice.reducer;
