import { FC } from 'react';
import code from '../../assets/images/gif/Code.gif';
import hours from '../../assets/images/gif/Time.gif';
import coffee from '../../assets/images/gif/Coffee.gif';
import pizzas from '../../assets/images/gif/Pizza.gif';
import mission from '../../assets/images/gif/Finish.gif';
import { IMetrics } from 'store/redux-toolkit/updates/updates-types';
import { LottieReact } from 'components/Lottie/Lottie';
import cups from '../../assets/jsonLottie/cups.json';
import hour from '../../assets/jsonLottie/hours.json';
import cod from '../../assets/jsonLottie/Cod.json';
import pizza from '../../assets/jsonLottie/Pizza.json';
import finish from '../../assets/jsonLottie/Finish.json';
import { useTranslation } from 'react-i18next';

interface IProps {
  metrics: null | IMetrics;
}

export const InfoBox: FC<IProps> = ({ metrics }) => {
  const { t } = useTranslation();

  return (
    <div className='project-tracker-box'>
      <div className='project-tracker'>
        <div className='d-flex project-tracker__icon lottie-wrap'>
          <img src={code} alt='tracker' />
          {/* <LottieReact json={cod} className='lottie-code' /> */}
        </div>
        <div className='project-tracker__info'>
          <p className='project-tracker__info-value'>{metrics?.lines_of_code || 0}</p>
          <p className='project-tracker__info-text'>{t('Page.Project.lines_of_code')}</p>
        </div>
      </div>
      <div className='project-tracker'>
        <div className='d-flex project-tracker__icon lottie-wrap'>
          <img src={hours} alt='tracker' />
          {/* <LottieReact json={hour} className='lottie-hours' /> */}
        </div>
        <div className='project-tracker__info'>
          <p className='project-tracker__info-value'>{metrics?.dev_hours || 0}</p>
          <p className='project-tracker__info-text'>{t('Page.Project.dev_hours')}</p>
        </div>
      </div>
      <div className='project-tracker'>
        <div className='d-flex project-tracker__icon lottie-wrap'>
          <img src={coffee} alt='tracker' />
          {/* <LottieReact json={cups} className='lottie-cup' /> */}
        </div>
        <div className='project-tracker__info'>
          <p className='project-tracker__info-value'>{metrics?.cups_of_coffee || 0}</p>
          <p className='project-tracker__info-text'>{t('Page.Project.cups_of_coffee')}</p>
        </div>
      </div>
      <div className='project-tracker'>
        <div className='d-flex project-tracker__icon lottie-wrap'>
          <img src={pizzas} alt='tracker' />
          {/* <LottieReact json={pizza} className='lottie-pizza' /> */}
        </div>
        <div className='project-tracker__info'>
          <p className='project-tracker__info-value'>{metrics?.pizzas || 0}</p>
          <p className='project-tracker__info-text'>{t('Page.Project.pizzas')}</p>
        </div>
      </div>
      <div className='project-tracker'>
        <div className='d-flex project-tracker__icon lottie-wrap'>
          <img src={mission} alt='tracker' />
          {/* <LottieReact json={finish} className='lottie-finish' /> */}
        </div>
        <div className='project-tracker__info'>
          <p className='project-tracker__info-value'>{metrics?.missions || 0}</p>
          <p className='project-tracker__info-text'>{t('Page.Project.mission')}</p>
        </div>
      </div>
    </div>
  );
};
