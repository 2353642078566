import { ArrowRightSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { FC, useState } from 'react';
import { IPropsSwiperBtn } from '../types';

const SlideNextButton: FC<IPropsSwiperBtn> = ({ swiperInstance, index, setIndex, setLen, len }) => {
  return (
    <button
      onClick={() => {
        swiperInstance?.slideNext();
        setIndex(swiperInstance?.activeIndex || 1);
        setLen?.(swiperInstance ? swiperInstance.slides.length - 1 : 1);
      }}
      className={classNames('slider-button slider-button--next', {
        'swiper-button-disabled': len === index,
      })}
      type='button'
      aria-label='Slide Next Button'
    >
      <ArrowRightSvg />
    </button>
  );
};

export default SlideNextButton;
