import { ERoutes } from 'enum/types';
import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import images from '../../assets/svg-icon/success-auth.svg';
import { useTranslation } from 'react-i18next';

export const Successful: FC = () => {
  const { t } = useTranslation();

  return (
    <section className='authorization-section'>
      <form className='form form--authorization'>
        <div className='form-icon'>
          <img src={images} alt='' />
        </div>
        <h2 className='form-title'>{t('Popup.title_successful')}</h2>
        <div className='form__body'>
          <div className='form-text form-text--ta-center'>
            <p>{t('Popup.text_successful')}</p>
          </div>
        </div>
        <div className='form__extra'>
          <Link to={ERoutes.home} className='button button--full-width'>
            {t('Popup.button')}
          </Link>
        </div>
      </form>
    </section>
  );
};
