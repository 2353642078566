import { RootState } from 'store/rootReducer';
import { IPayments, IPaymentsState } from './updates-types';
import { LoadingStatus } from 'enum/types';

export const selectPaymentsState = (state: RootState): IPaymentsState => state.peymentsSlice;

export const getPaymentsSelector = (state: RootState): IPayments | null =>
  selectPaymentsState(state).peymentsInfo;

export const getLoaderSelector = (state: RootState): LoadingStatus =>
  selectPaymentsState(state).loadingState;
