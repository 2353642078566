import { ERoutes } from 'enum/types';

export const linkList = [
  { title: 'Project', link: ERoutes.project, label: 'Navigation.project' },
  // { title: 'Updates', link: ERoutes.updates, label: 'Navigation.updates' },
  // { title: 'Comments', link: ERoutes.comments, label: 'Navigation.comments' },
  { title: 'Team', link: ERoutes.team, label: 'Navigation.team' },
  // { title: 'Backers', link: ERoutes.backers, label: 'Navigation.backers' },
  { title: 'Contacts', link: ERoutes.contacts, label: 'Navigation.contacts' },
];
