import { AxiosResponse, isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ERoutes } from './../../../enum/types';
import { t } from 'i18next';
import { showError } from '../../../utils/showError';
import { notificationContainer } from '../../../ui/notificationContainer';
import { clearErrors, resetAuthState } from './auth-slice';
import RequestService from '../../../services/http/interceptor/request-service';
import { endpoint } from '../../../services/endpoint';
import {
  IChangePasswordThunks,
  IRegistrationRequest,
  IResetPasswordThunks,
  IResponse,
} from './auth-types';
import { IForgotForm } from 'components/Forms/ForgotForm/types';
import { store } from 'store/store';

export const login = createAsyncThunk('auth/login', async (payload: any, thunkApi) => {
  try {
    const response = await RequestService.post(endpoint.auth.LOGIN, payload);
    thunkApi.dispatch(clearErrors());
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.data) {
        showError(error);
      }
      return thunkApi.rejectWithValue(error.response?.data.errors[0]);
    }
  }
});

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (payload: { token: string }, thunkApi) => {
    try {
      const response = await RequestService.put(endpoint.auth.REFRESH, payload);
      thunkApi.dispatch(clearErrors());
      return response.data.data;
    } catch (error) {
      store.dispatch(resetAuthState());
      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
        return thunkApi.rejectWithValue(error.response?.data.errors[0]);
      }
    }
  }
);

export const registration = createAsyncThunk(
  'auth/registration',
  async (payload: IRegistrationRequest, thunkApi) => {
    const { paramsApi, navigate } = payload;

    try {
      const response = await RequestService.post(endpoint.auth.REGISTRATION, paramsApi);
      thunkApi.dispatch(clearErrors());
      navigate(ERoutes.project);
      notificationContainer(t('Success.registration'), 'success');
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
        return thunkApi.rejectWithValue(error.response?.data.errors);
      }
    }
  }
);

export const confirmEmail = createAsyncThunk(
  'auth/confirm-email',
  async (payload: string, thunkApi) => {
    try {
      const response = await RequestService.put(endpoint.auth.VERIFY_EMAIL(payload));
      thunkApi.dispatch(clearErrors());
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
        return thunkApi.rejectWithValue(error.response?.data.errors[0]);
      }
    }
  }
);

export const forgotPassword = createAsyncThunk(
  'auth/forgot-password',
  async (payload: IForgotForm, thunkApi) => {
    try {
      const response = await RequestService.put(endpoint.auth.PASSWORD_RESET_SEND, payload);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
        return thunkApi.rejectWithValue(error.response?.data.errors);
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/reset-password',
  async (payload: IResetPasswordThunks, thunkApi) => {
    try {
      const response = await RequestService.post(endpoint.auth.PASSWORD_RESET, payload);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
        return thunkApi.rejectWithValue(error.response?.data.errors);
      }
    }
  }
);

export const changePassword = createAsyncThunk(
  'auth/change-password',
  async (payload: IChangePasswordThunks, thunkApi) => {
    try {
      const response = await RequestService.post(endpoint.auth.CHANGE_PASSWORD, payload);
      notificationContainer('Password changed', 'success');
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
        return thunkApi.rejectWithValue(error.response?.data.errors);
      }
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (payload: { token: string }, thunkApi) => {
    try {
      await RequestService.post(endpoint.auth.LOGOUT, payload);
      thunkApi.dispatch(resetAuthState());
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);
