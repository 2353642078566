import React, { FC, useState } from 'react';
import { FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { EyeCloseSvg, EyeOpenSvg, InformationSvg } from 'assets/svg-icon';
import { useLocation } from 'react-router-dom';
import { authInfo } from 'services/constants/authPathname';

const PasswordField: FC<FieldProps> = ({ field, form: { touched, errors }, ...props }) => {
  const { t } = useTranslation();
  const [isShowPass, setIsShowPass] = useState(false);
  const handleShowPass = () => setIsShowPass(!isShowPass);
  const { pathname } = useLocation();

  return (
    <div
      className={classNames('input', {
        'input--error': touched[field.name] && errors[field.name],
      })}
    >
      <div className='input-wrapper'>
        <input
          {...field}
          {...props}
          type={isShowPass ? 'text' : 'password'}
          className='input-item input-item--pr-90'
        />
        <div className='input-actions'>
          <button type='button' className='show-pass' onClick={handleShowPass}>
            <EyeCloseSvg />
            <EyeOpenSvg />
          </button>
          {authInfo.includes(pathname) && (
            <div className='input-info'>
              <div className='input-icon'>
                <InformationSvg />
              </div>
              <span className='tooltip tooltip--bottom tooltip--center '>
                <span className='tooltip__text '>
                  <span>
                    Passwords must have 6+ characters, at least 1 number, at least 1 letter, and no
                    whitespace.
                  </span>
                </span>
              </span>
            </div>
          )}
        </div>
      </div>
      {touched[field.name] && errors[field.name] && (
        <p className='input-notification input-notification--error'>
          {t(String(errors[field.name]))}
        </p>
      )}
    </div>
  );
};

export default PasswordField;
