import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import requestService from 'services/http/interceptor/request-service';
import { showError } from 'utils/showError';
import { endpoint } from 'services/endpoint';
import { clearErrors } from './myComments-slice';
import { IMyComentsRequest } from './myComments-types';

export const getMyComments = createAsyncThunk(
  'myComments/getMyComments',
  async (payload: IMyComentsRequest, thunkApi) => {
    try {
      const response = await requestService.get(endpoint.settings.GET_MY_COMMENTS, payload);
      thunkApi.dispatch(clearErrors());
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
      }
    }
  }
);
