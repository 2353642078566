import {
  AccountSvg,
  MyCommentsSvg,
  NotificationtSvg,
  // PaymentSvg,
  SecuritytSvg,
  TransactionSvg,
} from 'assets/svg-icon';
import { ERoutes } from 'enum/types';

export const listLink = [
  {
    link: ERoutes.account,
    svg: AccountSvg,
    title: 'Page.Settings.AccountBar.account_settings',
  },
  {
    link: ERoutes.security,
    svg: SecuritytSvg,
    title: 'Page.Settings.AccountBar.security_Password',
  },
  {
    link: ERoutes.notification,
    svg: NotificationtSvg,
    title: 'Page.Settings.AccountBar.notification_preferences',
  },
  // { link: ERoutes.payment, svg: PaymentSvg, title: 'Page.Settings.AccountBar.payment_management' },
  {
    link: ERoutes.transaction,
    svg: TransactionSvg,
    title: 'Page.Settings.AccountBar.transaction',
  },
  // { link: ERoutes.myComments, svg: MyCommentsSvg, title: 'Page.Settings.AccountBar.my_comments' },
];
