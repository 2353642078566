import { CloseToastifySvg } from 'assets/svg-icon';
import { LoadingStatus } from 'enum/types';
import { emailSchema } from 'form-validations/email-change-validation';
import { Field, Form, Formik } from 'formik';
import { useAppSelector } from 'hooks/redux';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getLoadingStatusSelector } from 'store/redux-toolkit/settings/account/account-selector';
import { IEmailChangeSubmitValue } from 'store/redux-toolkit/settings/account/account-types';
import Input from 'ui/Formik/Input';
import { LoadingSmall } from 'ui/LoadingSmall/LoadingSmall';

interface IProps {
  onClosePopup: () => void;
  emailChangeSubmit: (values: IEmailChangeSubmitValue) => void;
}

const initialValues = {
  email: '',
};

export const PopupChangeEmail: FC<IProps> = ({ onClosePopup, emailChangeSubmit }) => {
  const loadingStatus = useAppSelector(getLoadingStatusSelector);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={emailSchema}
      onSubmit={(values: IEmailChangeSubmitValue, { setSubmitting }) => {
        emailChangeSubmit(values);
        setSubmitting(false);
      }}
      validateOnBlur
      enableReinitialize
    >
      {({ isSubmitting, isValid, dirty }) => {
        return (
          <Form className='popup-window'>
            <div className='popup-window__inside'>
              <div className='popup'>
                <button onClick={onClosePopup} className='popup__close'>
                  <CloseToastifySvg />
                </button>
                <div className='popup-header'>
                  <h2 className='popup-title'>
                    {t('Page.Settings.Account.EmailAddressChange.change_title')}
                  </h2>
                </div>
                <div className='popup-body'>
                  <div className='popup-text '>
                    <p>{t('Page.Settings.Account.EmailAddressChange.change_note')}</p>
                  </div>
                  <div className='input'>
                    <div className='input-wrapper'>
                      <Field
                        name='email'
                        component={Input}
                        className='input-item'
                        type='text'
                        placeholder={t('Page.Settings.Account.EmailAddressChange.change_new_email')}
                      />
                    </div>
                  </div>
                </div>
                <div className='popup-footer'>
                  <div className='form-submit form-submit--mob-column'>
                    <button
                      onClick={onClosePopup}
                      className='button button--type3 button--full-width'
                      type='button'
                    >
                      {t('Global.cancel')}
                    </button>
                    <button
                      disabled={!(isValid && dirty) || isSubmitting}
                      className='button button--full-width'
                      type='submit'
                    >
                      {loadingStatus === LoadingStatus.LOADING ? (
                        <LoadingSmall />
                      ) : (
                        t('Global.continue')
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
