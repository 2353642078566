import { ButtonUp } from 'components/ButtonUp/ButtonUp';
import { ERoutes } from 'enum/types';
import { useAppSelector } from 'hooks/redux';
import useScrollPosition from 'hooks/useScrollPosition';
import { AfterRegistrationHeader } from 'layouts-element/AfterRegistration/AfterRegistration';
import Footer from 'layouts-element/Footer';
import { SettingsBar } from 'layouts-element/SettingsBar/SettingsBar';
import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const SettingsLayout: FC = () => {
  const scrollPosition = useScrollPosition();
  const { isAuth } = useAppSelector((store) => store.auth);
  const location = useLocation();

  if (!isAuth) return <Navigate to={ERoutes.project} state={{ from: location }} replace />;

  return (
    <div className='wrapper'>
      {scrollPosition > 600 ? <ButtonUp scrollPosition={scrollPosition} /> : null}

      <AfterRegistrationHeader />
      <main className='main'>
        <section className='account-section'>
          <div className='container container--big'>
            <div className='profile-layout'>
              <SettingsBar />
              <Outlet />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default SettingsLayout;
