import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from 'enum/types';
import { deleteProfile, getProfile, postEmail, postProfile, postUsername } from './account-thunks';

import { IProfileRespone, ISettingsAccountState } from './account-types';

const initialState: ISettingsAccountState = {
  profile: null,
  loadingState: LoadingStatus.NEVER,
  success: '',
  error: '',
  errors: null,
  popup: '',
};

export const settingsAccountSlice = createSlice({
  name: 'settingsAccount',
  initialState,
  reducers: {
    resetAuthState: () => initialState,
    clearErrors: (state) => {
      state.error = '';
      state.errors = null;
    },
    clearSuccess: (state) => {
      state.success = '';
    },
    activePopup: (state, { payload }) => {
      state.popup = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(getProfile.fulfilled, (state, { payload }: PayloadAction<IProfileRespone>) => {
        state.loadingState = LoadingStatus.SUCCESS;
        state.profile = payload;
      })
      .addCase(getProfile.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.errors = payload;
      })
      .addCase(postProfile.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(postProfile.fulfilled, (state, { payload }: PayloadAction<IProfileRespone>) => {
        state.loadingState = LoadingStatus.SUCCESS;
        state.profile = payload;
      })
      .addCase(postProfile.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.errors = payload;
      })
      .addCase(postUsername.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(postUsername.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.loadingState = LoadingStatus.SUCCESS;
        if (state.profile?.username) state.profile.username = payload;
      })
      .addCase(postUsername.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.errors = payload;
      })
      .addCase(postEmail.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(postEmail.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.loadingState = LoadingStatus.SUCCESS;
        if (state.profile?.email) state.profile.email = payload;
      })
      .addCase(postEmail.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.errors = payload;
      })
      .addCase(deleteProfile.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(deleteProfile.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.loadingState = LoadingStatus.SUCCESS;
      })
      .addCase(deleteProfile.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.errors = payload;
      });
  },
});

export const { resetAuthState, clearErrors, clearSuccess, activePopup } =
  settingsAccountSlice.actions;
export default settingsAccountSlice.reducer;
