import { Backers } from 'components/Backers/Backers';
import { FC } from 'react';

export const BackersPage: FC = () => {
  return (
    <section className='landing-section landing-section--comments'>
      <Backers />
    </section>
  );
};
