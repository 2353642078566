import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import requestService from 'services/http/interceptor/request-service';
import { showError } from 'utils/showError';
import { endpoint } from 'services/endpoint';
import { changeAvatar } from 'store/redux-toolkit/auth/auth-slice';
import { INotificationRespone, IProfileError } from './notification-types';
import { clearErrors } from './notification-slice';

export const getNotification = createAsyncThunk<INotificationRespone>(
  'settingsAccount/getNotification',
  async (_, thunkApi) => {
    try {
      const response = await requestService.get(endpoint.settings.GET_NOTIFICATION);
      thunkApi.dispatch(clearErrors());
      return response.data.data;
    } catch (error) {
      const err = error as IProfileError;

      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
      }
    }
  }
);

export const postNotification = createAsyncThunk(
  'settingsAccount/postNotification',
  async (payload: INotificationRespone, thunkApi) => {
    try {
      const response = await requestService.post(endpoint.settings.GET_NOTIFICATION, payload);
      thunkApi.dispatch(clearErrors());
      return response.data.data.receive_email_notifications;
    } catch (error) {
      const err = error as IProfileError;

      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
      }
    }
  }
);
