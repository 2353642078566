import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from 'enum/types';
import { getPeymentLink, getPeymentsInfo } from './updates-thunks';
import { IPaymentResponse, IPaymentsResponse, IPaymentsState } from './updates-types';

const initialState: IPaymentsState = {
  loadingState: LoadingStatus.NEVER,
  peymentsInfo: null,
  success: '',
  error: '',
  errors: null,
};

const peymentsSlice = createSlice({
  name: 'peyments',
  initialState,
  reducers: {
    resetPeymentsUsState: () => initialState,
    clearErrors: (state) => {
      state.error = '';
      state.errors = null;
    },
    clearSuccess: (state) => {
      state.success = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPeymentsInfo.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(
        getPeymentsInfo.fulfilled,
        (state, { payload }: PayloadAction<IPaymentsResponse>) => {
          state.loadingState = LoadingStatus.SUCCESS;
          state.peymentsInfo = payload.data;
        }
      )
      .addCase(getPeymentsInfo.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.errors = payload;
      })
      .addCase(getPeymentLink.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(getPeymentLink.fulfilled, (state, { payload }: PayloadAction<IPaymentsResponse>) => {
        state.loadingState = LoadingStatus.SUCCESS;
      })
      .addCase(getPeymentLink.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.errors = payload;
      });
  },
});

export const { resetPeymentsUsState, clearErrors, clearSuccess } = peymentsSlice.actions;
export default peymentsSlice.reducer;
