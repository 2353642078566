import { FC, MouseEvent } from 'react';
import eng from '../../../assets/custom/images/usa.svg';
import ua from '../../../assets/images/flag3.png';

interface IProps {
  onOpenPopupLan: (event: MouseEvent) => void;
}

export const LanguagePopup: FC<IProps> = ({ onOpenPopupLan }) => (
  <div className='dropdown dropdown--lang'>
    <ul className='dropdown-list'>
      <li>
        <button name='en' onClick={onOpenPopupLan} type='button'>
          <span className='dropdown-icon'>
            <img src={eng} alt='Icon language' />
          </span>
          English
        </button>
      </li>
      <li>
        <button name='ua' onClick={onOpenPopupLan} type='button'>
          <span className='dropdown-icon'>
            <img src={ua} alt='Icon language' />
          </span>
          Ukrainian
        </button>
      </li>
    </ul>
  </div>
);
