import { RemindOrangeSvg, RemindSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { ERoutes } from 'enum/types';
import { FC, ChangeEvent, useState, useEffect, MouseEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BtnBurger } from 'ui/BtnBurger/BtnBurger';
import { Button } from 'ui/Button/Button';
import { linkList } from './linkList';
import logo from '../../assets/images/logo-with-text.svg';
import { IPropsNavigation } from './types';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { notificationContainer } from 'ui/notificationContainer';
import { postNotification } from 'store/redux-toolkit/settings/notification/notification-thunks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getNotificationSelector } from 'store/redux-toolkit/settings/notification/notification-selector';
import { getBurgerSelector } from 'store/redux-toolkit/app/app-selector';
import { setBurger } from 'store/redux-toolkit/app/app-slice';
import { BakePopUp } from 'ui/BakePopUp/BakePopUp';
import { getPeymentLink } from 'store/redux-toolkit/peyments/updates-thunks';
import { getLoaderSelector } from 'store/redux-toolkit/peyments/updates-selector';

export const Navigation: FC<IPropsNavigation> = ({ pathname }) => {
  const { t } = useTranslation();
  const [scroll, setScroll] = useState(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuth } = useAppSelector((store) => store.auth);
  const notification = useAppSelector(getNotificationSelector);
  const burger = useAppSelector(getBurgerSelector);
  const [open, setOpen] = useState(false);
  const [num, setNum] = useState<string>('5');
  const [amount, setAmount] = useState<string>('1');
  const loading = useAppSelector(getLoaderSelector);

  const onGetSum = (event: ChangeEvent) => {
    const { id } = event.target as HTMLInputElement;
    setNum(id);
    setAmount(typeof +id === 'number' ? id : '1');
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const onScrollDown = (event: MouseEvent, link: string) => {
    event.preventDefault();
    const check = document.getElementById('header-content');
    dispatch(setBurger(!burger));
    document.body.style.overflow = '';

    if (check) {
      const element = document.getElementById('header-content')!;
      const header = document.getElementById('header-id')!;
      navigate(link);

      console.log(scroll, element?.clientHeight + header?.clientHeight);

      if (scroll !== element?.clientHeight + header?.clientHeight) {
        console.log('test');
        window.scrollTo({
          top: element?.clientHeight + header?.clientHeight,
          behavior: 'smooth',
        });
      }
    }
  };

  const onNotification = () => {
    isAuth
      ? dispatch(
          postNotification({
            receive_email_notifications: !notification?.receive_email_notifications,
          })
        )
      : notificationContainer('Page.Settings.you_need_to_log_in', 'info');
  };

  const onSubmit = () => {
    if (+amount < 1) {
      notificationContainer('Page.Project.number_must', 'info');
      return;
    }

    dispatch(
      getPeymentLink({
        close: setOpen,
        api: {
          amount: +amount || +num,
          currency: 'usd',
        },
      })
    );
  };

  const handleInput = (event: ChangeEvent) => {
    const { value } = event.target as HTMLInputElement;
    setAmount(value);
  };

  const onClosePpopUp = () => {
    setOpen(false);
  };

  const onBackProject = () => {
    setOpen(true);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className='header header--sticky'>
      {open ? (
        <BakePopUp
          onSubmit={onSubmit}
          onGetSum={onGetSum}
          onClosePpopUp={onClosePpopUp}
          handleInput={handleInput}
          num={num}
          amount={amount}
          loading={loading}
        />
      ) : null}
      <div className='header__container  '>
        <div className='header-left header-left--mob-only'>
          <Link to={ERoutes.project} className='logo'>
            <img src={logo} alt='logo' />
          </Link>
        </div>
        <div className={classNames('nav-block', { active: burger })}>
          <div className='nav-block__inner'>
            <nav className='main-nav'>
              {linkList.map((link, index) => (
                <div
                  key={index}
                  className={classNames('nav-item', {
                    active: pathname.includes(link.link),
                  })}
                >
                  <Link
                    onClick={(e) => onScrollDown(e, link.link)}
                    to={link.link}
                    className='nav-item__link'
                  >
                    {t(link.label)}
                  </Link>
                </div>
              ))}
            </nav>
            <CSSTransition in={scroll > 700} timeout={300} classNames='alert' unmountOnExit>
              <div className='header-right'>
                <Button
                  color={{ color: 'black' }}
                  onClick={onBackProject}
                  title={t('Page.Project.back_this_project')}
                  classNames='button button--size2'
                />

                <Button
                  onClick={onNotification}
                  title={t('Page.Project.remind_me')}
                  classNames='button button--size2  button--type3'
                  svg={
                    notification?.receive_email_notifications ? <RemindOrangeSvg /> : <RemindSvg />
                  }
                />
              </div>
            </CSSTransition>
          </div>
        </div>
        <BtnBurger activeBurger={burger} />
      </div>
    </header>
  );
};
