import { CheckOrangeSvg } from 'assets/svg-icon';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ChangeEvent, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getNotificationSelector } from 'store/redux-toolkit/settings/notification/notification-selector';
import {
  getNotification,
  postNotification,
} from 'store/redux-toolkit/settings/notification/notification-thunks';

export const NotificationPage: FC = () => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector(getNotificationSelector);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getNotification());
  }, []);

  const onNotification = (event: ChangeEvent) => {
    const { checked } = event.target as HTMLInputElement;
    dispatch(postNotification({ receive_email_notifications: checked }));
  };

  return (
    <div className='profile-content'>
      <div className='content-block'>
        <h2 className='block-title'>
          {t('Page.Settings.Notification.notification_preferences_title')}
        </h2>
        <div className='block-text'>
          <p>{t('Page.Settings.Notification.notification_preferences_desciption')}</p>
        </div>
      </div>
      <div className='content-block'>
        <div className='account-wrap'>
          <h2 className='block-subtitle'>
            {t('Page.Settings.Notification.notification_email_title')}
          </h2>
          {notification !== null && (
            <div className='checkbox checkbox--mt-16'>
              <label className='checkbox__label'>
                <input
                  onChange={onNotification}
                  type='checkbox'
                  className='hidden'
                  checked={notification.receive_email_notifications}
                />
                <span className='checkbox__item'>
                  <span className='checkbox__item-icon'>
                    <CheckOrangeSvg />
                  </span>
                </span>
                <span className='checkbox__text'>
                  {t('Page.Settings.Notification.btn_notification_email')}
                </span>
              </label>
            </div>
          )}
          <div className='block-text block-text--fw-700'>
            <p>{t('Page.Settings.Notification.notification_email_info')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
