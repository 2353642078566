import { DiscordSvg, FacebookFooterSvg, InstagramSvg, TwitterFooterSvg } from 'assets/svg-icon';
import { FC } from 'react';

export const FooterSocials: FC = () => (
  <div className='footer-social'>
    <a
      target='_blank'
      rel='noreferrer'
      href='https://www.facebook.com/playchtv/'
      className='d-flex footer-social__link d-flex footer-social__link--facebook'
      aria-label='Facebook'
    >
      <FacebookFooterSvg />
    </a>
    <a
      target='_blank'
      rel='noreferrer'
      href='https://www.instagram.com/playchtv/'
      className='d-flex footer-social__link d-flex footer-social__link--instagram'
      aria-label='Instagram'
    >
      <InstagramSvg />
    </a>
    <a
      target='_blank'
      rel='noreferrer'
      href='https://twitter.com/playchtv/'
      className='d-flex footer-social__link d-flex footer-social__link--twitter'
      aria-label='Twitter'
    >
      <TwitterFooterSvg />
    </a>
    <a
      target='_blank'
      rel='noreferrer'
      href='https://mas.to/@playch'
      className='d-flex footer-social__link d-flex footer-social__link--discord'
      aria-label='Discord'
    >
      <DiscordSvg />
    </a>
  </div>
);
